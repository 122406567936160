import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import noop from 'lodash/noop'

import Close from 'icons/close'
import ThemeContext from 'contexts/ThemeContext'

import styles from './styles.module.scss'

const GroupItem = ({ group, onDelete, onClick, isSelected }) => {
  const theme = useContext(ThemeContext)
  const [isHovered, setIsHovered] = useState(false)
  const mouseEnterHandler = () => setIsHovered(true)
  const mouseLeaveHandler = () => setIsHovered(false)
  const clickHandler = e => {
    e.stopPropagation()
    onClick(group)
  }
  const deleteHandler = e => {
    e.stopPropagation()
    onDelete({ id: group._id })
  }

  return (
    <div
      className={classNames(styles.list__item, styles[theme], { [styles.selected]: isSelected })}
      onClick={clickHandler}
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
    >
      <span>{group.title}</span>
      {isHovered && (
        <div className={styles.icon} onClick={deleteHandler}>
          <Close />
        </div>
      )}
    </div>
  )
}

GroupItem.propTypes = {
  group: PropTypes.shape({
    _id: PropTypes.string,
    title: PropTypes.string,
  }),
  onDelete: PropTypes.func,
  onClick: PropTypes.func,
  isSelected: PropTypes.bool,
}

GroupItem.defaultProps = {
  groups: [],
  onDelete: noop,
  onClick: noop,
  isSelected: false,
}

export default GroupItem
