import { createAction } from 'redux-actions'

export const actionModalOpen = createAction('MODAL_OPEN')
export const actionModalClose = createAction('MODAL_CLOSE')

export const actionShowLoading = createAction('SHOW_LOADING')
export const actionHideLoading = createAction('HIDE_LOADING')

export const actionToggleSidebar = createAction('TOGGLE_SIDEBAR')
export const actionCloseSidebar = createAction('CLOSE_SIDEBAR')

export const actionSetTheme = createAction('SET_THEME')
