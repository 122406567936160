import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import omit from 'lodash/omit'
import Landing from 'containers/Landing'
import Main from 'containers/Main'
import Login from 'containers/Login'
import Registration from 'containers/Registration'
import Settings from 'containers/Settings'
import NewRecipe from 'containers/NewRecipe'
import ViewRecipe from 'containers/ViewRecipe'
import EditRecipe from 'containers/EditRecipe'
import Themes from 'containers/Themes'
import Favorites from 'containers/Favorites'
import Sidebar from 'containers/Sidebar'
import Recover from 'containers/Recover'
import Agreement from 'containers/Agreement'
import GroupSettings from 'containers/GroupSettings'

export const ROUTES = {
  MAIN: '/',
  SETTINGS: '/settings',
  THEMES: '/themes',
  NEW_RECIPE: '/new_recipe',
  RECIPE: '/recipe/:id',
  FAVORITES: '/favorites',
  AGREEMENT: '/agreement',
  GROUP_SETTINGS: '/group_settings',
}

export const ROUTES_WITH_SIDEBAR = Object.values(omit(ROUTES, ['RECIPE', 'NEW_RECIPE']))

export const useRoutes = isAuth => {
  if (isAuth) {
    return (
      <>
        <Switch>
          <Route path={ROUTES_WITH_SIDEBAR} component={Sidebar} exact />
        </Switch>
        <Switch>
          <Route path="/" component={Main} exact />
          <Route path="/settings" component={Settings} exact />
          <Route path="/new_recipe" component={NewRecipe} exact />
          <Route path="/recipe/:id" component={ViewRecipe} exact />
          <Route path="/recipe/:id/edit" component={EditRecipe} exact />
          <Route path="/themes" component={Themes} exact />
          <Route path="/favorites" component={Favorites} exact />
          <Route path={ROUTES.GROUP_SETTINGS} component={GroupSettings} exact />
          <Redirect to="/" />
        </Switch>
      </>
    )
  }

  return (
    <Switch>
      <Route path="/" component={Landing} exact />
      <Route path="/login" component={Login} exact />
      <Route path="/registration" component={Registration} exact />
      <Route path="/recover" component={Recover} exact />
      <Route path="/agreement" component={Agreement} exact />
      <Redirect to="/" />
    </Switch>
  )
}

export const ROUTES_TITLE = {
  '/': 'Рецепты',
  '/favorites': 'Избранное',
  '/settings': 'Настройки',
  '/new_recipe': 'Добавить рецепт',
  '/recipe/:id': 'Рецепт',
  '/agreement': 'Соглашение об обработке персональных данных',
  '/group_settings': 'Настройка групп',
}
