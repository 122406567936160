import React from 'react'
import PropTypes from 'prop-types'

const Link = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.88959 9.36584L2.28724 11.9682C1.22001 13.0354 0.620453 14.4829 0.620453 15.9922C0.620453 17.5015 1.22001 18.9489 2.28724 20.0162C3.35446 21.0834 4.80193 21.6829 6.31122 21.6829C7.8205 21.6829 9.26797 21.0834 10.3352 20.0162L13.8025 16.547C14.446 15.9033 14.9258 15.1147 15.2015 14.2472C15.4772 13.3798 15.5408 12.4589 15.387 11.5618C15.2331 10.6647 14.8663 9.81759 14.3173 9.09158C13.7683 8.36558 13.0532 7.78186 12.232 7.38942L11.1205 8.50092C11.0076 8.61395 10.9095 8.74077 10.8284 8.87838C11.4629 9.06079 12.0388 9.40564 12.4992 9.87884C12.9596 10.352 13.2885 10.9372 13.4535 11.5765C13.6184 12.2158 13.6137 12.887 13.4397 13.5239C13.2657 14.1608 12.9285 14.7412 12.4615 15.2079L8.99608 18.6751C8.28426 19.387 7.31883 19.7869 6.31217 19.7869C5.3055 19.7869 4.34007 19.387 3.62825 18.6751C2.91643 17.9633 2.51653 16.9979 2.51653 15.9912C2.51653 14.9846 2.91643 14.0191 3.62825 13.3073L5.13238 11.8051C4.92016 11.0098 4.83817 10.1854 4.88959 9.36395V9.36584Z"
      fill={color}
    />
    <path
      d="M6.78066 10.1587L7.09742 9.84192C7.56735 9.37025 8.15368 9.03128 8.79692 8.85939C8.9688 8.21615 9.30777 7.62983 9.77944 7.1599L10.0962 6.84314C9.14206 6.78949 8.18976 6.97669 7.32693 7.38751C6.90964 8.26002 6.72755 9.21409 6.78066 10.1568V10.1587Z"
      fill={color}
    />
    <path
      d="M8.43845 5.81889C7.79489 6.46255 7.31513 7.25119 7.03942 8.11863C6.76372 8.98606 6.70012 9.90698 6.85397 10.8041C7.00783 11.7012 7.37464 12.5483 7.92362 13.2743C8.47259 14.0003 9.18772 14.584 10.009 14.9765L11.479 13.5046C10.8359 13.3321 10.2495 12.9933 9.77877 12.5224C9.30805 12.0515 8.96957 11.465 8.79734 10.8218C8.62511 10.1787 8.6252 9.50148 8.7976 8.85835C8.96999 8.21522 9.30862 7.6288 9.77946 7.15801L13.2448 3.69073C13.9567 2.97891 14.9221 2.57901 15.9288 2.57901C16.9354 2.57901 17.9009 2.97891 18.6127 3.69073C19.3245 4.40255 19.7244 5.36798 19.7244 6.37465C19.7244 7.38131 19.3245 8.34674 18.6127 9.05856L17.1085 10.5608C17.321 11.3574 17.4025 12.1825 17.3513 13.0019L19.9537 10.3996C21.0209 9.33235 21.6205 7.88488 21.6205 6.37559C21.6205 4.86631 21.0209 3.41884 19.9537 2.35162C18.8865 1.28439 17.439 0.68483 15.9297 0.68483C14.4204 0.68483 12.973 1.28439 11.9057 2.35162L8.43845 5.81889Z"
      fill={color}
    />
    <path
      d="M14.914 14.9765C15.3265 14.1142 15.5144 13.1616 15.4602 12.2072L15.1435 12.5239C14.6735 12.9956 14.0872 13.3346 13.444 13.5065C13.2721 14.1497 12.9331 14.736 12.4614 15.206L12.1447 15.5227C13.0988 15.5764 14.0511 15.3892 14.914 14.9784V14.9765Z"
      fill={color}
    />
  </svg>
)

Link.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

Link.defaultProps = {
  size: 22,
  color: 'currentColor',
}

export default Link
