import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import ReduxToastr from 'react-redux-toastr'
import debounce from 'lodash/debounce'
import App from 'containers/App'
import { actionToggleSidebar } from 'store/ui/actions'
import { selectIsSidebarOpen } from 'store/ui/selectors'
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'

import 'styles/common.scss'
import 'styles/fonts.scss'

import store from './store'
import * as serviceWorker from './serviceWorker'

const updateWindowWidth = () => {
  const isSidebarOpen = selectIsSidebarOpen(store.getState())

  if (window.innerWidth < 570 && isSidebarOpen) {
    store.dispatch(actionToggleSidebar())
  }
}

window.addEventListener('resize', debounce(updateWindowWidth, 100))

ReactDOM.render(
  <Provider store={store}>
    <App />
    <ReduxToastr />
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
