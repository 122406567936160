import React from 'react'
import PropTypes from 'prop-types'

const ArrowUp = ({ size, color }) => (
  <svg
    width={size}
    height={(12 * size) / 22}
    viewBox="0 0 22 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.8427 0.830106C11.1759 0.823639 11.5008 0.934175 11.761 1.14253L20.4408 8.13128C20.7362 8.36852 20.9254 8.71353 20.9667 9.09042C21.0081 9.46732 20.8982 9.84521 20.6613 10.141C20.4243 10.4367 20.0797 10.6262 19.7033 10.6675C19.3269 10.7089 18.9495 10.5989 18.654 10.3617L10.8998 4.09935L3.36486 10.4C3.22108 10.5212 3.05481 10.6127 2.8756 10.6693C2.69639 10.7258 2.50778 10.7464 2.3206 10.7298C2.13343 10.7131 1.95138 10.6596 1.78492 10.5723C1.61847 10.485 1.47088 10.3656 1.35066 10.221C1.21752 10.0765 1.11561 9.90601 1.05134 9.72024C0.987059 9.53446 0.961793 9.3374 0.977117 9.1414C0.992442 8.9454 1.04803 8.75468 1.14039 8.5812C1.23276 8.40771 1.35992 8.25519 1.5139 8.1332L9.94869 1.08848C10.2095 0.904685 10.5242 0.813715 10.8427 0.830106V0.830106Z"
      fill={color}
    />
  </svg>
)

ArrowUp.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

ArrowUp.defaultProps = {
  size: 22,
  color: 'currentColor',
}

export default ArrowUp
