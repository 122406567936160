import React, { useContext } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link, NavLink, withRouter } from 'react-router-dom'
import noop from 'lodash/noop'

import Logo from 'icons/logo'
import LogoText from 'icons/logoText'
import Menu from 'icons/menu'
import Profile from 'components/Profile'
import Search from 'components/Search'

import { selectUserData } from 'store/user/selectors'
import { actionToggleSidebar, actionModalOpen } from 'store/ui/actions'
import { actionLogout } from 'store/auth/actions'

import ThemeContext from 'contexts/ThemeContext'

import styles from './styles.module.scss'

const mapStatetoProps = state => ({
  user: selectUserData(state),
})

const mapDispatchToProps = {
  toggleSidebar: actionToggleSidebar,
  logout: actionLogout,
  openModal: actionModalOpen,
}

const Header = ({ user, toggleSidebar, logout, location, openModal }) => {
  const theme = useContext(ThemeContext)
  const isShowBurger = () =>
    !location.pathname.includes('/recipe/') &&
    !location.pathname.includes('/new_recipe') &&
    !location.pathname.includes('/settings') &&
    !location.pathname.includes('/themes')

  return (
    <header className={styles.header}>
      <div className={styles.left__section}>
        <div className={classNames(styles.header__logo, styles[theme])}>
          <Link to="/">
            <Logo size={30} />
            <LogoText size={180} />
          </Link>
        </div>
        {isShowBurger() && (
          <div className={classNames(styles.burger, styles[theme])} onClick={toggleSidebar}>
            <Menu size={28} />
          </div>
        )}
      </div>
      <div className={styles.center__section}>
        <div className={styles.menu}>
          <ul className={styles.menu__list}>
            <li className={classNames(styles.menu__item, styles[theme])}>
              <NavLink to="/" activeClassName={styles.active} exact>
                Рецепты
              </NavLink>
            </li>
            <li className={classNames(styles.menu__item, styles[theme])}>
              <NavLink to="/favorites" activeClassName={styles.active} exact>
                Избранное
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
      <div className={styles.right__section}>
        <Search />
        <Profile user={user} logout={logout} openModal={openModal} />
      </div>
    </header>
  )
}

Header.propTypes = {
  user: PropTypes.shape({}),
  toggleSidebar: PropTypes.func,
  logout: PropTypes.func,
  openModal: PropTypes.func,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
}

Header.defaultProps = {
  user: undefined,
  toggleSidebar: noop,
  logout: noop,
  openModal: noop,
}

export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(Header))
