import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import noop from 'lodash/noop'
import { SPECIFICATIONS } from 'utils/constants'

import styles from './styles.module.scss'

const Specifications = ({
  selectedItems,
  onChange,
  isView,
  labelClassName,
  containerClassName,
}) => {
  const handleChange = value => () => {
    onChange(value)
  }
  return (
    <div className={classNames(containerClassName, styles.wrapper)}>
      <div className={classNames(labelClassName ? labelClassName : styles.label)}>
        Характеристики:
      </div>
      <div className={styles.icons}>
        {SPECIFICATIONS.map(({ component: Component, name, value }) => (
          <div
            key={value}
            onClick={isView ? noop : handleChange(value)}
            title={name}
            className={classNames({ [styles.selected]: selectedItems.includes(value) })}
          >
            <Component />
          </div>
        ))}
      </div>
    </div>
  )
}

Specifications.propTypes = {
  selectedItems: PropTypes.arrayOf(PropTypes.string),
  handleChange: PropTypes.func,
  isView: PropTypes.bool,
  labelClassName: PropTypes.string,
  containerClassName: PropTypes.string,
}

Specifications.defaultProps = {
  selectedItems: [],
  handleChange: noop,
  isView: false,
  labelClassName: '',
  containerClassName: '',
}

export default Specifications
