import { all } from 'redux-saga/effects'

import authSaga from 'store/auth/sagas'
import userSaga from 'store/user/sagas'
import dictionariesSaga from 'store/dictionaries/sagas'
import recipesSaga from 'store/recipes/sagas'
import groupsSaga from 'store/groups/sagas'
import reportSaga from 'store/reports/sagas'

export default function* rootSaga() {
  yield all([
    ...authSaga,
    ...userSaga,
    ...dictionariesSaga,
    ...recipesSaga,
    ...groupsSaga,
    ...reportSaga,
  ])
}
