import { createSelector } from 'reselect'

export const selectRecipes = state => state.recipes

export const selectAllRecipes = createSelector(selectRecipes, (recipes = {}) => recipes.recipes)
export const selectRecipe = createSelector(selectRecipes, (recipes = {}) => recipes.recipe)
export const selectStatusLoading = createSelector(
  selectRecipes,
  (recipes = {}) => recipes.statusLoading
)
