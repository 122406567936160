import React from 'react'
import PropTypes from 'prop-types'

const Password = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5577 0.144568C12.2669 0.144568 11.0639 0.667645 10.0723 1.65841C8.94616 2.78457 8.43078 4.16918 8.58155 5.64918C8.69462 6.74764 9.19616 7.85534 10 8.84611L4.42308 14.423L3.41308 13.413C2.92078 12.9184 2.51232 13.3284 2.01924 13.8223L1.53847 14.3269C1.04462 14.8184 0.636931 15.2038 1.13001 15.6969L2.13847 16.7076L1.34616 17.5C1.22883 17.6165 1.1357 17.7551 1.07216 17.9078C1.00861 18.0605 0.975896 18.2242 0.975896 18.3896C0.975896 18.5549 1.00861 18.7187 1.07216 18.8714C1.1357 19.024 1.22883 19.1626 1.34616 19.2792C1.83847 19.7707 2.63155 19.7707 3.12539 19.2792L11.8754 10.5292C12.8231 11.123 13.8523 11.4423 14.8554 11.4423C16.1454 11.4423 17.3508 10.9192 18.3415 9.92764C19.4685 8.80226 19.9831 7.41764 19.8315 5.93764C19.6962 4.60611 18.9892 3.26611 17.8608 2.13918C16.5823 0.859183 15.0554 0.143799 13.5577 0.143799V0.144568ZM13.6062 1.73072C14.6777 1.73072 15.7908 2.27918 16.7546 3.24534C17.6115 4.09918 18.1462 5.09303 18.2454 6.05765C18.3477 7.06072 17.9946 8.01611 17.2115 8.79841C16.51 9.49841 15.6923 9.85534 14.8077 9.85534C13.7354 9.85534 12.6246 9.30611 11.6585 8.34149C10.8023 7.48534 10.2677 6.4938 10.1685 5.52918C10.0669 4.52611 10.4185 3.57149 11.2023 2.78841C11.9039 2.08841 12.7215 1.73072 13.6054 1.73072H13.6062Z"
      fill={color}
    />
  </svg>
)

Password.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

Password.defaultProps = {
  size: 20,
  color: 'currentColor',
}

export default Password
