import { makeRequest } from 'utils/request'

export const sendBugReport = data => {
  return makeRequest({
    url: '/api/reports/',
    method: 'post',
    headers: {
      Accept: 'application/json;charset=UTF-8',
    },
    data: { ...data, type: 'BUG' },
  })
}
