import React, { useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'

import Eye from 'icons/eye'
import EyeClose from 'icons/eyeClose'

import styles from './styles.module.scss'

const Input = ({
  label,
  type,
  name,
  placeholder,
  id,
  value,
  icon,
  handleChange,
  handleBlur,
  handleFocus,
  handleKeyPress,
  inputClassName,
  containerClassName,
  sizePasswordIcon,
  errors,
  touched,
  disabled,
  submitCount,
  refElement,
}) => {
  const [showPassword, setShowPassword] = useState(false)
  const toggleEye = () => setShowPassword(!showPassword)
  const renderIconEye = () => {
    return (
      <div className={styles.eye} onClick={toggleEye}>
        {showPassword ? <Eye size={sizePasswordIcon} /> : <EyeClose size={sizePasswordIcon} />}
      </div>
    )
  }
  const renderLabel = () => {
    if (label.includes('*')) {
      return (
        <label htmlFor={id}>
          {label.replace('*', '')}
          <span>*</span>
        </label>
      )
    }
    return <label htmlFor={id}>{label}</label>
  }
  const isError = () => {
    return (touched[name] || submitCount > 0) && !!errors[name]
  }

  return (
    <div className={classNames(containerClassName, styles.container)}>
      {label && renderLabel()}
      {icon && icon}
      <div className={styles.inputWrapper}>
        <input
          ref={refElement}
          type={showPassword ? 'text' : type}
          name={name}
          id={id || name}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onKeyPress={handleKeyPress}
          className={classNames(styles.input, inputClassName, { [styles.error]: isError() })}
          disabled={disabled}
        />
        {type === 'password' && renderIconEye()}
      </div>
      {isError() && <div className={styles.errorMessage}>{errors[name]}</div>}
    </div>
  )
}

Input.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  icon: PropTypes.node,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  handleFocus: PropTypes.func,
  handleKeyPress: PropTypes.func,
  inputClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  disabled: PropTypes.bool,
  sizePasswordIcon: PropTypes.number,
  submitCount: PropTypes.number,
  errors: PropTypes.shape({}),
  touched: PropTypes.shape({}),
  refElement: PropTypes.shape({}),
}

Input.defaultProps = {
  label: '',
  type: 'text',
  name: '',
  placeholder: '',
  id: '',
  value: '',
  icon: null,
  handleChange: noop,
  handleBlur: noop,
  handleFocus: noop,
  handleKeyPress: noop,
  inputClassName: '',
  containerClassName: '',
  disabled: false,
  sizePasswordIcon: 24,
  submitCount: null,
  errors: {},
  touched: {},
  refElement: null,
}

export default Input
