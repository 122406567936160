import { makeRequest } from 'utils/request'

export const getUser = id =>
  makeRequest({
    url: `/api/users/${id}`,
  })

export const updateUser = (id, data) =>
  makeRequest({
    url: `/api/users/${id}`,
    method: 'post',
    data,
  })

export const updateUserAvatar = (id, file) => {
  const form = new FormData()
  form.append('file', file)

  return makeRequest({
    url: `/api/users/${id}/avatar`,
    method: 'post',
    headers: {
      Accept: 'application/json;charset=UTF-8',
    },
    data: form,
  })
}

export const deleteUserAvatar = id =>
  makeRequest({
    url: `/api/users/${id}/avatar`,
    method: 'delete',
  })

export const updateUserCredentials = (id, data) =>
  makeRequest({
    url: `/api/users/${id}/credentials`,
    method: 'post',
    data,
  })

export const updateUserTheme = (id, data) =>
  makeRequest({
    url: `/api/users/${id}/theme`,
    method: 'post',
    data,
  })

export const recoverPassword = email =>
  makeRequest({
    url: `/api/users/recover`,
    method: 'post',
    data: { email },
  })
