import { handleActions } from 'redux-actions'
import produce from 'immer'
import sortBy from 'lodash/sortBy'
import { actionLoadGroupsSuccess, actionUpdateGroupSuccess } from './actions'

const initialState = {
  entities: [],
}

export default handleActions(
  {
    [actionLoadGroupsSuccess]: (state, { payload }) => ({
      ...state,
      entities: sortBy(payload, 'sort_index'),
    }),

    [actionUpdateGroupSuccess]: (state, { payload }) =>
      produce(state, newState => {
        payload.forEach(group => {
          const index = newState.entities.findIndex(g => g._id === group._id)

          if (index !== -1) {
            newState.entities[index] = group
          }
        })

        newState.entities = sortBy(newState.entities, 'sort_index')
      }),
  },
  initialState
)
