import { handleActions } from 'redux-actions'
import { actionLoadUserSuccess, actionDeleteAvatarSuccess } from './actions'

const initialState = {}

export default handleActions(
  {
    [actionLoadUserSuccess]: (
      state,
      { payload: { name, last_name, birthday, sex, email, id, avatar } }
    ) => ({
      ...state,
      data: {
        name,
        last_name,
        birthday,
        sex,
        email,
        userId: id,
        avatar,
      },
    }),

    [actionDeleteAvatarSuccess]: state => ({
      ...state,
      data: {
        ...state.data,
        avatar: {},
      },
    }),
  },
  initialState
)
