import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { MODAL_TYPES } from 'utils/constants'

import RectangleButton from 'components/Button/RectangleButton'
import GroupItem from 'components/GroupItem'
import ThemeContext from 'contexts/ThemeContext'

import { actionModalOpen } from 'store/ui/actions'
import { actionDeleteGroupRequest } from 'store/groups/actions'
import { actionChangeFilterParams } from 'store/filters/actions'
import { selectGroupsEntities } from 'store/groups/selectors'
import { selectFilterParams } from 'store/filters/selectors'

import styles from './styles.module.scss'

const mapStateToProps = state => ({
  groups: selectGroupsEntities(state),
  filterParams: selectFilterParams(state),
})

const mapDispatchToProps = {
  openModal: actionModalOpen,
  deleteGroup: actionDeleteGroupRequest,
  changeFilterParams: actionChangeFilterParams,
}

const Group = ({ openModal, groups, deleteGroup, changeFilterParams, filterParams }) => {
  const theme = useContext(ThemeContext)
  const addGroupeHandler = () => {
    openModal(MODAL_TYPES.GROUP)
  }
  const selectHandler = group => {
    changeFilterParams({
      key: 'group',
      value: group.title,
      isSelected: filterParams.group === group.title,
    })
  }

  return (
    <div className={styles.groups}>
      <div className={classNames(styles.header, styles[theme])}>
        <span>Группы</span>
        <Link to="/group_settings">Настроить</Link>
      </div>
      <div className={styles.list}>
        {groups.map(group => (
          <GroupItem
            key={group._id}
            group={group}
            onDelete={deleteGroup}
            onClick={selectHandler}
            isSelected={filterParams.group === group.title}
          />
        ))}
      </div>
      <RectangleButton
        title="+ Добавить группу"
        onClick={addGroupeHandler}
        externalStyle={classNames(styles.reset, styles[theme])}
        theme="whiteSimple"
      />
    </div>
  )
}

Group.propTypes = {
  openModal: PropTypes.func,
  deleteGroup: PropTypes.func,
  changeFilterParams: PropTypes.func,
  groups: PropTypes.arrayOf(PropTypes.shape({})),
  filterParams: PropTypes.shape({
    group: PropTypes.string,
  }),
}

Group.defaultProps = {
  openModal: noop,
  deleteGroup: noop,
  changeFilterParams: noop,
  groups: [],
  filterParams: {},
}

export default connect(mapStateToProps, mapDispatchToProps)(Group)
