import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { getCoverSrcRecipe } from 'utils/utils'

import styles from './styles.module.scss'

const Cover = ({ cover, coverUrl }) => {
  return (
    <div
      className={classNames(styles.cover__img, {
        [styles.fullWidth]: (cover && cover.fileURL) || coverUrl,
      })}
    >
      <img src={getCoverSrcRecipe({ cover, coverUrl })} alt={cover ? cover.name : 'Обложка'} />
    </div>
  )
}

Cover.propTypes = {
  cover: PropTypes.shape({
    fileURL: PropTypes.string,
    name: PropTypes.string,
  }),
  coverUrl: PropTypes.string,
}

Cover.defaultProps = {
  cover: undefined,
  coverUrl: '',
}

export default Cover
