import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import ClickOutside from 'react-outside-click-handler'

import styles from './styles.module.scss'

const Profile = ({ user, logout, openModal }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const toggleArrow = () => setIsExpanded(!isExpanded)
  const bugReportHandler = () => openModal('bugReport')

  return (
    <ClickOutside onOutsideClick={isExpanded ? toggleArrow : noop}>
      <div className={styles.profile__container} onClick={toggleArrow}>
        <div className={styles.profile__avatar}>
          {user.avatar && user.avatar.file_url && (
            <img src={`/${user.avatar.file_url}`} alt="avatar" />
          )}
          {(!user.avatar || !user.avatar.file_url) && <span>{user.name[0]}</span>}
        </div>
        {isExpanded && (
          <div className={styles.profile__menu}>
            <div className={styles.menu__header}>
              <div className={styles.avatar}>
                {user.avatar && user.avatar.file_url && (
                  <img src={`/${user.avatar.file_url}`} alt="avatar" />
                )}
                {(!user.avatar || !user.avatar.file_url) && <span>{user.name[0]}</span>}
              </div>
              <div className={styles.content}>
                <div className={styles.content__name}>{user.name}</div>
                <div className={styles.content__email}>{user.email}</div>
              </div>
            </div>
            <ul className={styles.menu__list}>
              <li className={styles.menu__item}>
                <Link to="/settings">Профиль</Link>
              </li>
              <li className={styles.menu__item}>
                <Link to="/themes">Темы рабочего пространства</Link>
              </li>
              <li className={`${styles.menu__item} ${styles.notLink}`} onClick={bugReportHandler}>
                Сообщить об ошибке
              </li>
            </ul>
            <div className={styles.menu__footer} onClick={logout}>
              Выйти
            </div>
          </div>
        )}
      </div>
    </ClickOutside>
  )
}

Profile.propTypes = {
  user: PropTypes.shape({
    avatar: PropTypes.shape({
      file_url: PropTypes.string,
    }),
    name: PropTypes.string,
    email: PropTypes.string,
  }),
  logout: PropTypes.func,
  openModal: PropTypes.func,
}

Profile.defaultProps = {
  user: {
    name: '',
    email: '',
  },
  logout: noop,
  openModal: noop,
}

export default Profile
