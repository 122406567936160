import React, { useEffect, useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import noop from 'lodash/noop'

import CardsList from 'components/CardsList'
import Pencil from 'icons/pancil'

import { selectAllRecipes, selectStatusLoading } from 'store/recipes/selectors'
import { selectFilterParams } from 'store/filters/selectors'
import {
  actionLoadMyRecipesRequest,
  actionUpdateFavoriteRecipeRequest,
} from 'store/recipes/actions'

import styles from './styles.module.scss'

const mapDispatchToProps = {
  loadRecipes: actionLoadMyRecipesRequest,
  updateFavoriteRecipe: actionUpdateFavoriteRecipeRequest,
}

const mapStateToProps = state => ({
  recipes: selectAllRecipes(state),
  filterParams: selectFilterParams(state),
  statusLoading: selectStatusLoading(state),
})

const Main = ({
  recipes,
  loadRecipes,
  updateFavoriteRecipe,
  filterParams,
  statusLoading,
  history,
}) => {
  const favouriteHandler = recipe => {
    updateFavoriteRecipe({ id: recipe._id, isFavorites: !recipe.isFavorites })
  }

  const addHandler = useCallback(() => {
    history.push('/new_recipe')
  }, [history])

  const renderAddButton = () => (
    <div className={styles.add} onClick={addHandler}>
      <Pencil />
      <span>Добавить рецепт</span>
    </div>
  )

  useEffect(() => {
    if (!recipes.length && statusLoading !== 'success') {
      loadRecipes()
    }
  }, [loadRecipes, recipes, statusLoading])

  return (
    <div className={styles.mainWrapper}>
      {/* {filterParams.group && <div className={styles.titleGroup}>{filterParams.group}</div>} */}
      {recipes.length > 0 && <CardsList list={recipes} onFavoriteClick={favouriteHandler} />}
      {!recipes.length && (
        <div className={styles.emptyContent}>
          <span className={styles.title}>Начните создавать свою книгу</span>
          <p>Нет добавленных рецептов</p>
          {renderAddButton()}
        </div>
      )}
    </div>
  )
}

Main.propTypes = {
  loadRecipes: PropTypes.func,
  updateFavoriteRecipe: PropTypes.func,
  recipes: PropTypes.arrayOf(PropTypes.shape({})),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  filterParams: PropTypes.shape({
    group: PropTypes.string,
  }),
  statusLoading: PropTypes.string,
}

Main.defaultProps = {
  loadRecipes: noop,
  updateFavoriteRecipe: noop,
  recipes: [],
  history: {},
  filterParams: {},
  statusLoading: '',
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main))
