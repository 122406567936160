import { makeRequest } from 'utils/request'

export const createRecipe = data => {
  return makeRequest({
    url: '/api/recipes',
    method: 'post',
    headers: {
      Accept: 'application/json;charset=UTF-8',
    },
    data,
  })
}

export const createRecipeCover = file => {
  const form = new FormData()
  form.append('file', file)

  return makeRequest({
    url: '/api/recipes/cover',
    method: 'post',
    headers: {
      Accept: 'application/json;charset=UTF-8',
    },
    data: form,
  })
}

export const updateRecipeCover = (recipeId, file) => {
  const form = new FormData()
  form.append('file', file)

  return makeRequest({
    url: `/api/recipes/${recipeId}/cover`,
    method: 'patch',
    headers: {
      Accept: 'application/json;charset=UTF-8',
    },
    data: form,
  })
}

export const updateRecipe = (recipeId, data) =>
  makeRequest({
    url: `/api/recipes/${recipeId}`,
    method: 'patch',
    headers: {
      Accept: 'application/json;charset=UTF-8',
    },
    data,
  })

export const updateFavoriteRecipe = (recipeId, data) =>
  makeRequest({
    url: `/api/recipes/${recipeId}/favorite`,
    method: 'post',
    headers: {
      Accept: 'application/json;charset=UTF-8',
    },
    data,
  })

export const loadMyRecipes = userID =>
  makeRequest({
    url: `/api/recipes?user_id=${userID}`,
  })

export const loadRecipe = recipeId =>
  makeRequest({
    url: `/api/recipes/${recipeId}`,
  })

export const deleteRecipe = recipeId =>
  makeRequest({
    url: `/api/recipes/${recipeId}`,
    method: 'delete',
  })
