import React from 'react'
import PropTypes from 'prop-types'

const User = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.24 20.2C4.71058 20.2 0.23999 15.7294 0.23999 10.2C0.23999 4.67054 4.71058 0.199951 10.24 0.199951C15.7694 0.199951 20.24 4.67054 20.24 10.2C20.24 15.7294 15.7106 20.2 10.24 20.2ZM10.24 1.37642C5.35764 1.37642 1.41646 5.3176 1.41646 10.2C1.41646 15.0823 5.35764 19.0235 10.24 19.0235C15.1223 19.0235 19.0635 15.0823 19.0635 10.2C19.0635 5.3176 15.0635 1.37642 10.24 1.37642Z"
      fill={color}
    />
    <path
      d="M4.47529 17.4353L3.41647 16.9647C3.71058 16.2589 4.65176 15.8471 5.65176 15.3765C6.65176 14.9059 7.88705 14.3765 7.88705 13.7294V12.8471C7.53411 12.553 6.94588 11.9059 6.82823 10.9647C6.53411 10.6706 6.06352 10.1412 6.06352 9.43533C6.06352 9.02357 6.24 8.67063 6.35764 8.43533C6.24 7.96474 6.12235 7.08239 6.12235 6.37651C6.12235 4.08239 7.71058 2.55298 10.24 2.55298C10.9459 2.55298 11.8282 2.72945 12.2988 3.25886C13.4165 3.49415 14.3576 4.78827 14.3576 6.37651C14.3576 7.37651 14.1812 8.20004 14.0635 8.6118C14.1812 8.78827 14.2988 9.08239 14.2988 9.43533C14.2988 10.2 13.8871 10.7294 13.5341 10.9647C13.4165 11.9059 12.8871 12.4942 12.5341 12.7883V13.7294C12.5341 14.2589 13.5929 14.6706 14.5341 15.0236C15.6518 15.4353 16.8282 15.9059 17.24 16.8471L16.1223 17.2589C15.9459 16.7883 15.0047 16.4353 14.1223 16.1412C12.8282 15.6706 11.3576 15.1412 11.3576 13.7883V12.2589L11.6518 12.0824C11.6518 12.0824 12.3576 11.6118 12.3576 10.6706V10.2589L12.7106 10.0824C12.7694 10.0824 13.0635 9.90592 13.0635 9.43533C13.0635 9.31769 12.9459 9.14121 12.8871 9.08239L12.6518 8.8471L12.7694 8.55298C12.7694 8.55298 13.0635 7.6118 13.0635 6.43533C13.0635 5.31768 12.4165 4.49416 11.8871 4.49416H11.5341L11.3576 4.20004C11.3576 3.96474 10.9459 3.72945 10.24 3.72945C8.41647 3.72945 7.29882 4.72945 7.29882 6.37651C7.29882 7.14121 7.59294 8.43533 7.59294 8.43533L7.65176 8.72945L7.41647 9.02357C7.35764 9.02357 7.24 9.20004 7.24 9.43533C7.24 9.72945 7.59294 10.0824 7.76941 10.2L8.0047 10.3765V10.6706C8.0047 11.553 8.76941 12.0236 8.76941 12.0824L9.06353 12.2589V13.7883C9.06353 15.2 7.53411 15.9059 6.12235 16.4942C5.47529 16.7295 4.59294 17.1412 4.47529 17.4353V17.4353Z"
      fill={color}
    />
  </svg>
)

User.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

User.defaultProps = {
  size: 20,
  color: 'currentColor',
}

export default User
