import React from 'react'
import PropTypes from 'prop-types'

const Diet = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.7903 21.4582C22.7434 22.2402 22.4071 22.901 21.7815 23.4406C21.1559 23.9802 20.4286 24.25 19.5997 24.25H4.585C3.75606 24.25 3.02879 23.9802 2.40318 23.4406C1.77757 22.901 1.43348 22.2402 1.37092 21.4582L0.0336775 3.90982C-0.0288836 2.90885 0.252641 2.04863 0.878252 1.32918C1.50386 0.609726 2.30152 0.25 3.27121 0.25H20.7961C21.7658 0.25 22.5674 0.609726 23.2008 1.32918C23.8343 2.04863 24.1197 2.90885 24.0571 3.90982L22.7903 21.4582ZM12.0454 3.25293C10.4814 3.25293 9.02293 3.64003 7.67004 4.41422C6.31716 5.18842 5.27317 6.30279 4.53808 7.75733L9.65245 9.79839L7.75215 6.02126C7.61139 5.86486 7.54101 5.685 7.54101 5.48167C7.54101 5.27835 7.6153 5.1024 7.76388 4.95381C7.91247 4.80523 8.08842 4.73094 8.29174 4.73094C8.49507 4.73094 8.66711 4.80914 8.80787 4.96554L12.585 9.28226C13.3044 9.34482 13.8988 9.52468 14.368 9.82185L19.5527 7.75733C18.8176 6.30279 17.7737 5.18842 16.4208 4.41422C15.0679 3.64003 13.6094 3.25293 12.0454 3.25293Z"
      fill={color}
    />
  </svg>
)

Diet.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

Diet.defaultProps = {
  size: 25,
  color: 'currentColor',
}

export default Diet
