import { handleActions } from 'redux-actions'
import {
  actionModalOpen,
  actionModalClose,
  actionShowLoading,
  actionHideLoading,
  actionToggleSidebar,
  actionSetTheme,
  actionCloseSidebar,
} from './actions'

const initialState = {
  isModalOpened: false,
  modalType: '',
  isLoading: false,
  isSidebarOpen: window.innerWidth > 920,
  theme: 'default',
}

export default handleActions(
  {
    [actionModalOpen]: (state, { payload }) => ({
      ...state,
      isModalOpened: true,
      modalType: payload,
    }),

    [actionModalClose]: state => ({
      ...state,
      isModalOpened: false,
      modalType: '',
    }),

    [actionShowLoading]: state => ({
      ...state,
      isLoading: true,
    }),

    [actionHideLoading]: state => ({
      ...state,
      isLoading: false,
    }),

    [actionToggleSidebar]: state => ({
      ...state,
      isSidebarOpen: !state.isSidebarOpen,
    }),

    [actionCloseSidebar]: state => ({
      ...state,
      isSidebarOpen: false,
    }),

    [actionSetTheme]: (state, { payload }) => ({
      ...state,
      theme: payload,
    }),
  },
  initialState
)
