import React from 'react'
import PropTypes from 'prop-types'

const AddRecipe = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.25 24.025H6.25V8.75H15V6.25H6.25C4.875 6.25 3.75 7.375 3.75 8.75V23.75C3.75 25.125 4.875 26.25 6.25 26.25H21.25C22.625 26.25 23.75 25.125 23.75 23.75V15H21.25V24.025Z"
      fill={color}
    />
    <path
      d="M23.75 2.5H21.25V6.25H17.5C17.5125 6.2625 17.5 8.75 17.5 8.75H21.25V12.4875C21.2625 12.5 23.75 12.4875 23.75 12.4875V8.75H27.5V6.25H23.75V2.5ZM8.75 11.25H18.75V13.75H8.75V11.25ZM8.75 15V17.5H18.75V15H15H8.75ZM8.75 18.75H18.75V21.25H8.75V18.75Z"
      fill={color}
    />
  </svg>
)

AddRecipe.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

AddRecipe.defaultProps = {
  size: 30,
  color: 'currentColor',
}

export default AddRecipe
