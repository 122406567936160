import React, { useEffect, useState, useCallback, useContext } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import noop from 'lodash/noop'
import classNames from 'classnames'
import { Formik } from 'formik'
import { toastr } from 'react-redux-toastr'
import { TIME_OF_DAY } from 'utils/constants'

import ThemeContext from 'contexts/ThemeContext'
import Loader from 'components/Loader'
import Cover from 'components/Cover'
import Specifications from 'components/Specifications'
import RectangleButton from 'components/Button/RectangleButton'
import Select from 'components/Select'
import Input from 'components/Input'
import Checkbox from 'components/Checkbox'
import Textarea from 'components/Textarea'

import {
  actionLoadMyRecipesRequest,
  actionUpdateRecipeRequest,
  actionLoadRecipeRequest,
} from 'store/recipes/actions'
import { selectAllRecipes, selectRecipe } from 'store/recipes/selectors'
import { selectCountries, selectCategories } from 'store/dictionaries/selectors'
import { selectGroupsEntities } from 'store/groups/selectors'

import { getValidationSchema } from 'containers/NewRecipe/validation'

import styles from './styles.module.scss'

const mapStateToProps = state => ({
  recipes: selectAllRecipes(state),
  recipe: selectRecipe(state),
  countries: selectCountries(state),
  categories: selectCategories(state),
  groups: selectGroupsEntities(state),
})

const mapDispatchToProps = {
  loadRecipes: actionLoadMyRecipesRequest,
  loadRecipe: actionLoadRecipeRequest,
  updareRecipe: actionUpdateRecipeRequest,
}

function ViewRecipe({
  recipes,
  recipe,
  match,
  history,
  loadRecipe,
  updareRecipe,
  countries,
  categories,
  groups,
}) {
  const theme = useContext(ThemeContext)
  const [stateRecipe, setRecipe] = useState(null)
  const [file, setFile] = useState(null)
  const getRecipeById = useCallback(() => {
    const recipe = recipes.find(item => item._id === match.params.id)
    setRecipe(recipe)
  }, [match.params.id, recipes])
  const uploadHandler = event => {
    const file = event.target.files[0]

    if (file) {
      if (file.size > 2097152) {
        toastr.error('Размер файла не может превышать 2МБ')
        return
      }

      const fileURL = URL.createObjectURL(file)

      setFile({ name: file.name, fileURL, file })
    }
  }
  const changeInputHandler = (setFieldValue, setFieldTouched) => event => {
    const { value, name } = event.target
    setFieldValue(name, value)
    setFieldTouched(name, true, false)
  }
  const selectHandler = (setFieldValue, setFieldTouched) => (value, fieldName) => {
    setFieldValue(fieldName, value.name || value.title)
    setFieldTouched(fieldName, true, false)
  }
  const checkboxHandler = (values, setFieldValue, setFieldTouched) => param => {
    const { time_of_day: timeOfDay } = values
    const newTimeOfDay = timeOfDay.includes(param.value)
      ? timeOfDay.filter(item => item !== param.value)
      : timeOfDay.concat([param.value])

    setFieldValue('time_of_day', newTimeOfDay)
    setFieldTouched('time_of_day', true, false)
  }
  const specificationsHandler = (values, setFieldValue, setFieldTouched) => value => {
    const { specifications } = values
    const newSpecifications = specifications.includes(value)
      ? specifications.filter(item => item !== value)
      : specifications.concat([value])

    setFieldValue('specifications', newSpecifications)
    setFieldTouched('specifications', true, false)
  }
  const saveHandler = values => {
    updareRecipe({
      recipe: values,
      history,
      file: file ? file.file : null,
    })
    history.push(`/recipe/${match.params.id}`)
  }
  const cancelHandler = () => history.goBack()

  useEffect(() => {
    if (recipe) {
      setRecipe(recipe)
    }
  }, [recipe, setRecipe])

  useEffect(() => {
    if (!recipes.length) {
      loadRecipe({ recipeId: match.params.id, history })
    } else {
      getRecipeById()
    }
  }, [recipes, loadRecipe, match.params.id, history, getRecipeById])

  return (
    <div className={classNames(styles.wrapper, styles[theme])}>
      {!stateRecipe && <Loader />}
      {stateRecipe && (
        <Formik
          initialValues={stateRecipe}
          validationSchema={getValidationSchema()}
          onSubmit={saveHandler}
        >
          {({
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            submitCount,
            values,
            errors,
            touched,
          }) => (
            <>
              <div className={styles.container}>
                <div className={styles.header}>
                  <div className={styles.buttons}>
                    <div>
                      <RectangleButton
                        title="Сохранить"
                        onClick={handleSubmit}
                        externalStyle={styles.save}
                        theme="blueWithWhiteBorder"
                      />
                      <RectangleButton
                        title="Отмена"
                        onClick={cancelHandler}
                        externalStyle={styles.cancel}
                        theme="whiteWithRedBorder"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.titleWrapper}>
                  <h1 className={styles.title}>{stateRecipe.title}</h1>
                </div>
                <div className={styles.body}>
                  <div className={styles.row}>
                    <div className={styles.col}>
                      <div className={styles.cover}>
                        <Cover
                          cover={file ? file : stateRecipe.cover}
                          coverUrl={stateRecipe.coverUrl}
                        />
                        <div className={styles.cover__upload}>
                          <input
                            className={styles.inputfile}
                            id="avatar"
                            type="file"
                            name="avatar"
                            onChange={uploadHandler}
                            accept="image/*"
                          />
                          <label htmlFor="avatar"> Загрузить</label>
                        </div>
                      </div>
                    </div>
                    <div className={styles.col}>
                      <div className={styles.field}>
                        <Input
                          label="Название*"
                          placeholder="Введите название рецепта"
                          type="text"
                          name="title"
                          id="title"
                          value={values.title}
                          inputClassName={styles.input}
                          containerClassName={styles.inputContainer}
                          handleChange={changeInputHandler(setFieldValue, setFieldTouched)}
                          errors={errors}
                          touched={touched}
                          submitCount={submitCount}
                        />
                        <Input
                          label="Ссылка на источник*"
                          placeholder="Введите ссылку на источник"
                          type="text"
                          name="link"
                          id="link"
                          value={values.link}
                          inputClassName={styles.input}
                          containerClassName={styles.inputContainer}
                          handleChange={changeInputHandler(setFieldValue, setFieldTouched)}
                          errors={errors}
                          touched={touched}
                          submitCount={submitCount}
                        />
                      </div>
                      <div className={styles.field}>
                        <Select
                          label="Категория*"
                          placeholder="Выберите категорию"
                          id="category"
                          items={categories}
                          selectedItems={values.category}
                          selectClassName={styles.select}
                          containerClassName={styles.selectContainer}
                          handleSelect={selectHandler(setFieldValue, setFieldTouched)}
                          maxHeight={230}
                          errors={errors}
                          touched={touched}
                          submitCount={submitCount}
                        />
                        <Select
                          label="География блюда"
                          placeholder="Выберите кухню мира"
                          id="country"
                          items={countries}
                          selectedItems={values.country}
                          selectClassName={styles.select}
                          containerClassName={styles.selectContainer}
                          handleSelect={selectHandler(setFieldValue, setFieldTouched)}
                          maxHeight={230}
                        />
                      </div>
                      {groups.length > 0 && (
                        <div className={styles.singelField}>
                          <Select
                            label="Группа"
                            placeholder="Выберите группу"
                            id="group"
                            items={groups}
                            selectedItems={values.group}
                            selectClassName={styles.select}
                            containerClassName={styles.selectContainer}
                            handleSelect={selectHandler(setFieldValue, setFieldTouched)}
                            maxHeight={230}
                          />
                        </div>
                      )}
                      <div className={styles.field}>
                        <Checkbox
                          label="Подходит на:"
                          id="time_of_day"
                          items={TIME_OF_DAY}
                          selectedItems={values.time_of_day}
                          containerClassName={styles.checkboxContainer}
                          checkboxClassName={styles.checkbox}
                          handleChange={checkboxHandler(values, setFieldValue, setFieldTouched)}
                          maxHeight={230}
                        />
                      </div>
                      <div className={styles.field}>
                        <Specifications
                          onChange={specificationsHandler(values, setFieldValue, setFieldTouched)}
                          selectedItems={values.specifications}
                          labelClassName={styles.fieldTitle}
                          containerClassName={styles.specContainer}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.container}>
                <div className={styles.row}>
                  <Textarea
                    id="description"
                    label="Описание"
                    name="description"
                    placeholder="Введите описание рецепта"
                    value={values.description}
                    textareaClassName={styles.textarea}
                    containerClassName={styles.inputContainer}
                    onChange={changeInputHandler(setFieldValue, setFieldTouched)}
                  />
                </div>
              </div>
            </>
          )}
        </Formik>
      )}
    </div>
  )
}

ViewRecipe.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.shape({})),
  categories: PropTypes.arrayOf(PropTypes.shape({})),
  recipes: PropTypes.arrayOf(PropTypes.shape({})),
  groups: PropTypes.arrayOf(PropTypes.shape({})),
  recipe: PropTypes.shape({}),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
    url: PropTypes.string,
  }),
  history: PropTypes.shape({
    goBack: PropTypes.func,
    push: PropTypes.func,
  }),
  loadRecipe: PropTypes.func,
  updareRecipe: PropTypes.func,
}

ViewRecipe.defaultProps = {
  countries: [],
  categories: [],
  recipes: [],
  groups: [],
  recipe: null,
  match: {},
  history: {},
  loadRecipe: noop,
  updareRecipe: noop,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewRecipe))
