import { createAction } from 'redux-actions'

export const actionLoadUserRequest = createAction('LOAD_USER_REQUEST')
export const actionLoadUserSuccess = createAction('LOAD_USER_SUCCESS')

export const actionUpdateUserRequest = createAction('UPDATE_USER_REQUEST')
export const actionUpdateUserCredentialsRequest = createAction('UPDATE_USER_CREDENTIALS_REQUEST')

export const actionUpdateUserAvatarRequest = createAction('UPDATE_USER_AVATAR_REQUEST')
export const actionUpdateUserThemeRequest = createAction('UPDATE_USER_THEME_REQUEST')

export const actionRecoverPasswordRequest = createAction('RECOVER_PASSWORD_REQUEST')

export const actionDeleteAvatarRequest = createAction('DELETE_AVATAR_REQUEST')
export const actionDeleteAvatarSuccess = createAction('DELETE_AVATAR_SUCCESS')
