import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import noop from 'lodash/noop'

import LogoText from 'icons/logoText'
import Email from 'icons/email'
import Input from 'components/Input'
import RectangleButton from 'components/Button/RectangleButton'

import { actionRecoverPasswordRequest } from 'store/user/actions'

import styles from './styles.module.scss'

const mapDispatchToProps = {
  onRecover: actionRecoverPasswordRequest,
}

const Login = ({ onRecover, history }) => {
  const [fields, setFields] = useState({ email: '' })
  const [errors, setError] = useState({ email: '' })
  const changeHandler = event => {
    const { value, name } = event.target
    setFields({ ...fields, [name]: value })
    if (errors.email || errors.password) {
      setError({ ...errors, [name]: '' })
    }
  }
  const recoverHandler = () => {
    const { email } = fields
    const errors = {
      email: !email.trim() ? 'Необходимо заполнить поле Email' : '',
    }

    errors.email || errors.password ? setError(errors) : onRecover({ email, history })
  }
  const keyPressHandler = e => {
    if (e.key === 'Enter') {
      recoverHandler()
    }
  }

  return (
    <div className={styles.recover}>
      <div className={styles.recover__container}>
        <div className={styles.recover__form}>
          <div className={styles.form__logo}>
            <Link to="/">
              <LogoText size={200} />
            </Link>
          </div>
          <div className={styles.info}>
            На указанный вами адрес электронной почты будет выслан новый пароль.
          </div>
          <div className={styles.form__inputs}>
            <div className={styles.inputWrapper}>
              <Input
                placeholder="Email"
                type="text"
                name="email"
                id="email"
                value={fields.email}
                inputClassName={styles.input}
                containerClassName={styles.email}
                icon={<Email />}
                handleChange={changeHandler}
                handleKeyPress={keyPressHandler}
                error={errors.email}
              />
              {errors.email && <div className={styles.error}>{errors.email}</div>}
            </div>
          </div>
          <RectangleButton
            title="Сбросить пароль"
            externalStyle={styles.recoverButtons}
            onClick={recoverHandler}
          />
        </div>
      </div>
    </div>
  )
}

Login.propTypes = {
  onRecover: PropTypes.func,
  history: PropTypes.shape({}),
}

Login.defaultProps = {
  onRecover: noop,
  history: noop,
}

export default withRouter(connect(null, mapDispatchToProps)(Login))
