import { handleActions } from 'redux-actions'
import Cookies from 'js-cookie'
import { actionAuthSuccess, actionAuthClear } from './actions'

const initialState = {
  token: Cookies.get('token') || null,
  userId: Cookies.get('userId') || null,
}

export default handleActions(
  {
    [actionAuthSuccess]: (state, { payload: { token, userId } }) => ({
      ...state,
      token,
      userId,
    }),
    [actionAuthClear]: state => ({
      ...state,
      token: null,
      userId: null,
    }),
  },
  initialState
)
