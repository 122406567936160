import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import { ROUTES } from 'routes'

import Filter from 'containers/Filter'
import Group from 'containers/Group'

import { selectIsSidebarOpen } from 'store/ui/selectors'
import { selectCountries, selectCategories } from 'store/dictionaries/selectors'

import ThemeContext from 'contexts/ThemeContext'

import styles from './styles.module.scss'

const mapStateToProps = state => ({
  isSidebarOpen: selectIsSidebarOpen(state),
  countries: selectCountries(state),
  categories: selectCategories(state),
})

const Sidebar = ({ isSidebarOpen, countries, categories, location }) => {
  const theme = useContext(ThemeContext)
  return (
    <div className={classNames(styles.sidebar, styles[theme], { [styles.close]: !isSidebarOpen })}>
      {![ROUTES.SETTINGS, ROUTES.THEMES].includes(location.pathname) && (
        <>
          <Filter categories={categories} countries={countries} />
          <Group />
        </>
      )}
    </div>
  )
}

Sidebar.propTypes = {
  isSidebarOpen: PropTypes.bool,
  countries: PropTypes.arrayOf(PropTypes.shape({})),
  categories: PropTypes.arrayOf(PropTypes.shape({})),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
}

Sidebar.defaultProps = {
  isSidebarOpen: true,
  countries: [],
  categories: [],
  location: {},
}

export default withRouter(connect(mapStateToProps, null)(Sidebar))
