import React from 'react'
import PropTypes from 'prop-types'

const Email = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99999 1.625C5.39999 1.625 1.66666 5.35833 1.66666 9.95833C1.66666 14.5583 5.39999 18.2917 9.99999 18.2917H14.1667V16.625H9.99999C6.38332 16.625 3.33332 13.575 3.33332 9.95833C3.33332 6.34167 6.38332 3.29167 9.99999 3.29167C13.6167 3.29167 16.6667 6.34167 16.6667 9.95833V11.15C16.6667 11.8083 16.075 12.4583 15.4167 12.4583C14.7583 12.4583 14.1667 11.8083 14.1667 11.15V9.95833C14.1667 7.65833 12.3 5.79167 9.99999 5.79167C7.69999 5.79167 5.83332 7.65833 5.83332 9.95833C5.83332 12.2583 7.69999 14.125 9.99999 14.125C11.15 14.125 12.2 13.6583 12.95 12.9C13.4917 13.6417 14.425 14.125 15.4167 14.125C17.0583 14.125 18.3333 12.7917 18.3333 11.15V9.95833C18.3333 5.35833 14.6 1.625 9.99999 1.625ZM9.99999 12.4583C8.61666 12.4583 7.49999 11.3417 7.49999 9.95833C7.49999 8.575 8.61666 7.45833 9.99999 7.45833C11.3833 7.45833 12.5 8.575 12.5 9.95833C12.5 11.3417 11.3833 12.4583 9.99999 12.4583Z"
      fill={color}
    />
  </svg>
)

Email.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

Email.defaultProps = {
  size: 20,
  color: 'currentColor',
}

export default Email
