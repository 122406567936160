import { takeEvery, call, put, select, all } from 'redux-saga/effects'
import { toastr } from 'react-redux-toastr'
import { loadGroups, createGroup, deleteGroup, updateGroup } from 'api/groups'
import { getSortIndex } from 'utils/utils'
import { getBatchRequestData, getBatchRequestErrors } from 'utils/api'

import { actionChangeFilterParams } from 'store/filters/actions'
import {
  actionLoadGroupsRequest,
  actionLoadGroupsSuccess,
  actionCreateGroupRequest,
  actionDeleteGroupRequest,
  actionUpdateGroupRequest,
  actionUpdateGroupSuccess,
} from './actions'
import { selectGroupsEntities } from './selectors'

function* loadGroupsSaga() {
  const result = yield call(loadGroups)

  if (result.error) {
    toastr.error(result.error.message || 'Произошла ошибка. Попробуйте повторить позднее')
    return
  }

  yield put(actionLoadGroupsSuccess(result.data))
}

function* createGroupSaga({ payload }) {
  const { title } = payload
  const groups = yield select(selectGroupsEntities)
  const sortIndex = !groups.length ? 0 : getSortIndex(groups) + 1
  const result = yield call(createGroup, { title, sort_index: sortIndex })

  if (result.error) {
    toastr.error(result.error.message || 'Не удалось создать группу')
    return
  }

  yield put(actionLoadGroupsSuccess(result.data))
}

function* deleteGroupSaga({ payload }) {
  const { id } = payload
  const result = yield call(deleteGroup, id)

  if (result.error) {
    toastr.error(result.error.message || 'Не удалось удалить группу')
    return
  }

  const groups = yield select(selectGroupsEntities)

  yield put(actionChangeFilterParams({ key: 'group', value: '' }))
  yield put(actionLoadGroupsSuccess(groups.filter(group => group._id !== id)))
}

function* updateGroupSaga({ payload }) {
  const result = yield all(payload.map(({ id, ...rest }) => call(updateGroup, id, rest)))
  const data = getBatchRequestData(result)
  const errors = getBatchRequestErrors(result)

  if (errors.length) {
    errors.forEach(error => toastr.error(error.message || 'Не удалось обновить группу'))
  }

  if (data.length) {
    yield put(actionUpdateGroupSuccess(data))
  }
}

function* watchLoadGroups() {
  yield takeEvery(actionLoadGroupsRequest, loadGroupsSaga)
}

function* watchCreateGroup() {
  yield takeEvery(actionCreateGroupRequest, createGroupSaga)
}

function* watchDeleteGroup() {
  yield takeEvery(actionDeleteGroupRequest, deleteGroupSaga)
}

function* watchUpdateGroup() {
  yield takeEvery(actionUpdateGroupRequest, updateGroupSaga)
}

export default [watchLoadGroups(), watchCreateGroup(), watchDeleteGroup(), watchUpdateGroup()]
