import React, { useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { toastr } from 'react-redux-toastr'
import noop from 'lodash/noop'

import Input from 'components/Input'
import TextArea from 'components/Textarea'
import RectangleButton from 'components/Button/RectangleButton'
import BugIcon from 'icons/bug'

import { actionModalClose } from 'store/ui/actions'
import { actionBugReportRequest } from 'store/reports/actions'

import styles from './styles.module.scss'

const mapDispatchToProps = {
  sendBugReport: actionBugReportRequest,
  closeModal: actionModalClose,
}

const BugReportModal = ({ sendBugReport, closeModal }) => {
  const [fields, setFields] = useState({ topic: 'Сообщение об ошибке', message: '' })
  const changeInputHandler = e => {
    const { value, name } = e.target
    setFields({ ...fields, [name]: value })
  }
  const sendBugReportHandler = () => {
    if (!fields.topic.trim()) {
      toastr.error('Поле "Тема" не может быть пустым')
    }

    if (!fields.message.trim()) {
      toastr.error('Поле "Сообщение" не может быть пустым')
    }

    sendBugReport(fields)
    closeModal()
  }

  return (
    <div className={styles.content}>
      <div className={styles.title}>
        <BugIcon />
        Сообщить об ошибке
      </div>
      <div className={styles.body}>
        <Input
          placeholder="Тема"
          type="text"
          name="topic"
          value={fields.topic}
          inputClassName={styles.input}
          containerClassName={styles.inputContainer}
          handleChange={changeInputHandler}
          disabled={true}
        />
        <TextArea
          placeholder="Сообщение"
          name="message"
          value={fields.message}
          textareaClassName={styles.input}
          containerClassName={styles.textareaContainer}
          onChange={changeInputHandler}
        />
        <RectangleButton
          title="Отправить"
          externalStyle={styles.button}
          theme="blue"
          disabled={!fields.topic || !fields.message}
          onClick={sendBugReportHandler}
        />
      </div>
    </div>
  )
}

BugReportModal.propTypes = {
  sendBugReport: PropTypes.func,
  closeModal: PropTypes.func,
}

BugReportModal.defaultProps = {
  sendBugReport: noop,
  closeModal: noop,
}

export default connect(null, mapDispatchToProps)(BugReportModal)
