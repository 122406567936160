import * as yup from 'yup'

export const getValidationSchema = () =>
  yup.object({
    title: yup
      .string()
      .trim()
      .min(3, 'Название не должно быть короче 3 символов')
      .required('Поле "Название" обязательно к заполнению'),
    link: yup
      .string()
      .trim()
      .required('Поле "Ссылка на источник" обязательно к заполнению'),
    category: yup
      .string()
      .trim()
      .required('Поле "Категория" обязательно к заполнению'),
  })
