import { call, takeEvery, put } from 'redux-saga/effects'
import Cookies from 'js-cookie'
import { toastr } from 'react-redux-toastr'

import { authenticate, registration } from 'api/auth'
import { actionShowLoading, actionHideLoading, actionModalClose } from 'store/ui/actions'
import { actionLoadUserSuccess } from 'store/user/actions'
import {
  actionAuthRequest,
  actionAuthSuccess,
  actionRegistrationRequest,
  actionAuthClear,
  actionLogout,
} from './actions'

function* auth({ payload }) {
  yield put(actionShowLoading())

  const { email, password } = payload
  const result = yield call(authenticate, email, password)

  if (result.error) {
    toastr.error(result.error.message || 'Произошла ошибка. Попробуйте повторить позднее')
    yield put(actionHideLoading())
    return
  }

  Cookies.set('token', result.data.token)
  Cookies.set('userId', result.data.userId)

  yield put(actionLoadUserSuccess(result.data))
  yield put(actionHideLoading())
  yield put(actionModalClose())
  yield put(actionAuthSuccess(result.data))
}

function* register({ payload }) {
  yield put(actionShowLoading())

  const { values, history } = payload
  const { name, email, password } = values
  const result = yield call(registration, name, email, password)

  if (result.error) {
    toastr.error(result.error.message || 'Произошла ошибка. Попробуйте авторизоваться позднее')
    return
  }

  toastr.success('Пользователь успешно создан')
  history.push('/login')
}

function* logout() {
  yield put(actionAuthClear())

  Cookies.remove('token')
  Cookies.remove('userId')
}

function* watchAuth() {
  yield takeEvery(actionAuthRequest, auth)
}

function* watchRegistration() {
  yield takeEvery(actionRegistrationRequest, register)
}

function* watchLogout() {
  yield takeEvery(actionLogout, logout)
}

export default [watchAuth(), watchRegistration(), watchLogout()]
