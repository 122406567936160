import React from 'react'
import PropTypes from 'prop-types'

const Eye = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 3.75C5.63875 3.75 1.8575 6.29 0 10C1.8575 13.71 5.63875 16.25 10 16.25C14.3613 16.25 18.1425 13.71 20 10C18.1425 6.29 14.3613 3.75 10 3.75ZM14.9312 7.065C16.1062 7.815 17.1025 8.81875 17.85 10C17.1048 11.1811 16.1082 12.1833 14.9312 12.935C13.4588 13.8758 11.748 14.3757 10.0006 14.3757C8.25329 14.3757 6.54243 13.8758 5.07 12.935C3.89357 12.1826 2.8971 11.1806 2.15125 10C2.94549 8.74142 4.02452 7.68722 5.30125 6.9225C5.02648 7.67781 4.93785 8.48822 5.04286 9.28507C5.14787 10.0819 5.44342 10.8417 5.90448 11.5C6.36554 12.1584 6.97851 12.6959 7.69144 13.067C8.40438 13.4381 9.19627 13.6318 10 13.6318C10.8037 13.6318 11.5956 13.4381 12.3086 13.067C13.0215 12.6959 13.6345 12.1584 14.0955 11.5C14.5566 10.8417 14.8521 10.0819 14.9571 9.28507C15.0622 8.48822 14.9735 7.67781 14.6987 6.9225C14.7762 6.96875 14.8538 7.01625 14.93 7.065H14.9312ZM10 8.125C9.99983 8.62245 9.80207 9.09945 9.4502 9.45108C9.09834 9.80271 8.6212 10.0002 8.12375 10C7.6263 9.99983 7.1493 9.80207 6.79767 9.4502C6.44604 9.09834 6.24858 8.6212 6.24875 8.12375C6.24892 7.6263 6.44668 7.1493 6.79855 6.79767C7.15042 6.44604 7.62755 6.24858 8.125 6.24875C8.62245 6.24892 9.09945 6.44668 9.45108 6.79855C9.80271 7.15042 10.0002 7.62755 10 8.125Z"
      fill={color}
    />
  </svg>
)

Eye.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

Eye.defaultProps = {
  size: 20,
  color: 'currentColor',
}

export default Eye
