import React from 'react'
import PropTypes from 'prop-types'

const Vegan = ({ size, color }) => (
  <svg
    width={size}
    height={(23 * size) / 24}
    viewBox="0 0 24 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.0936 0.844859L23.0951 0.851984C22.9477 0.530234 22.4741 0.318359 21.4672 0.576734C21.0334 0.687734 20.4746 0.933734 20.0246 1.46923C16.7164 -1.95752 11.9036 1.20673 6.33373 4.87011C5.86798 5.17536 5.40148 5.48286 4.93648 5.78436C0.961855 8.36661 0.625855 12.0506 0.77848 14.0175C0.950605 16.2214 1.89148 18.4196 3.29436 19.8975C4.69686 21.375 6.76048 22.3421 8.81286 22.4831C10.644 22.6065 14.0636 22.1715 16.386 17.8466C16.6594 17.3372 16.9345 16.8285 17.211 16.3207C20.1154 10.9762 22.6699 6.27298 20.9989 2.84548L21.3086 2.64598C21.8178 2.35182 22.3484 2.09648 22.896 1.88211C23.5537 1.62336 23.0936 0.844859 23.0936 0.844859ZM13.5172 15.0435C10.3309 20.9794 6.42073 21.9889 3.85236 19.2844C1.28548 16.5787 0.16048 10.2641 5.61673 6.71848C11.0715 3.17361 16.6091 -0.930391 19.5026 2.11761C22.395 5.16523 16.7025 9.10611 13.5172 15.0435Z"
      fill={color}
    />
    <path
      d="M6.4478 9.38249C4.1948 11.8549 4.47193 15.4046 5.86955 16.0879C7.27693 16.7887 9.33605 15.6945 10.8694 14.0407C12.3762 12.3589 13.3516 10.1235 12.6728 8.62424C12.0094 7.13624 8.70343 6.91049 6.4478 9.38249ZM8.0213 10.4089C7.88818 10.7546 7.3358 11.5755 7.03093 12.3135C6.6938 13.0466 6.5003 13.6834 6.12493 13.923C5.74093 14.2211 5.25005 13.3725 6.2048 11.8646C7.17755 10.4224 8.20318 10.0612 8.0213 10.4089Z"
      fill={color}
    />
  </svg>
)

Vegan.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

Vegan.defaultProps = {
  size: 24,
  color: 'currentColor',
}

export default Vegan
