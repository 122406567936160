/* eslint-disable no-param-reassign */
/* eslint-disable no-useless-escape */
import React, { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import classNames from 'classnames'
import { EMPTY_USER } from 'utils/models'

import Input from 'components/Input'
import Select from 'components/Select'
import RadioButton from 'components/RadioButton'
import RectangleButton from 'components/Button/RectangleButton'

import { selectUserData } from 'store/user/selectors'
import {
  actionUpdateUserRequest,
  actionUpdateUserCredentialsRequest,
  actionUpdateUserAvatarRequest,
  actionDeleteAvatarRequest,
} from 'store/user/actions'
import { actionShowLoading, actionHideLoading, actionCloseSidebar } from 'store/ui/actions'
import { getArrayYears, getArrayMonth, getArrayDay } from 'utils/utils'

import styles from './styles.module.scss'

const YEARS = getArrayYears()
const MONTHS = getArrayMonth()
const DAYS = getArrayDay()

const mapStateToProps = state => ({
  user: selectUserData(state),
})

const mapDispatchToProps = {
  updateUser: actionUpdateUserRequest,
  updateUserAvatar: actionUpdateUserAvatarRequest,
  updateUserCredentials: actionUpdateUserCredentialsRequest,
  showLoader: actionShowLoading,
  hideLoader: actionHideLoading,
  closeSidebar: actionCloseSidebar,
  deleteAvatar: actionDeleteAvatarRequest,
}

const Settings = ({
  user,
  updateUser,
  updateUserAvatar,
  updateUserCredentials,
  showLoader,
  hideLoader,
  closeSidebar,
  deleteAvatar,
}) => {
  const [fields, setFields] = useState(EMPTY_USER)
  const [touchFields, setTouchFields] = useState(false)
  const [userUploaded, setUserUploaded] = useState(false)
  const applyUserToState = useCallback(() => {
    const { name, last_name, birthday, sex, email, avatar } = user

    setFields({
      ...fields,
      name,
      last_name,
      sex,
      email,
      day: birthday ? birthday.split('-')[0] : '',
      month: birthday
        ? MONTHS.find(month => month.id === parseInt(birthday.split('-')[1])).name
        : '',
      year: birthday ? birthday.split('-')[2] : '',
      avatar: {
        name: avatar.file_name,
        fileURL: avatar.file_url,
      },
    })
    setUserUploaded(true)
  }, [user, fields])

  const touchFieldsHandler = () => {
    if (!touchFields) {
      setTouchFields(true)
    }
  }

  const changeInputHandler = e => {
    const { name, value } = e.target
    setFields({ ...fields, [name]: value })
    touchFieldsHandler()
  }

  const selectHandler = (value, id) => {
    setFields({ ...fields, [id]: value.name })
    touchFieldsHandler()
  }

  const changeRadioButtonHandler = value => {
    setFields({ ...fields, sex: value.value })
    touchFieldsHandler()
  }

  const submitHandler = () => {
    const { name, last_name, sex, day, month, year, file } = fields

    if (!name.trim()) {
      return toastr.error('Заполните поле Имя')
    }

    const selectedMonth = MONTHS.find(m => m.name === month)

    updateUser({
      name,
      last_name,
      sex,
      birthday: day && month && year ? `${day}-${selectedMonth.id}-${year}` : '',
      file,
    })

    setFields({ ...fields, file: null })
    setTouchFields(false)
  }

  const cancelHandler = () => {
    applyUserToState()
  }

  const resetPasswordHandler = () => {
    setFields({ ...fields, password: '', newPassword: '', confirmPassword: '' })
  }

  const submitPasswordHandler = () => {
    const { password, newPassword, confirmPassword } = fields

    if (!password.trim() || !newPassword.trim() || !confirmPassword.trim()) {
      return toastr.error('Поля паролей не должны быть пустыми')
    }

    if (newPassword.trim().length < 6) {
      return toastr.error('Новый пароль не должен быть меньше 6 символов')
    }

    if (newPassword.trim() !== confirmPassword.trim()) {
      return toastr.error('Пароли не совпадают')
    }

    updateUserCredentials({ password, newPassword })
    resetPasswordHandler()
  }

  const uploadHandler = e => {
    const fileTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif']
    const file = e.target.files[0]

    if (file) {
      if (!fileTypes.includes(file.type)) {
        return toastr.error('Неверный тип файла')
      }

      const fileURL = URL.createObjectURL(file)
      const avatar = { name: file.name, fileURL }
      setFields({ ...fields, avatar, file })
      updateUserAvatar({ file })
      e.target.value = null
    }
  }

  const deleteButtonHandler = () => {
    deleteAvatar()
    setFields({ ...fields, avatar: {} })
  }

  useEffect(() => {
    closeSidebar()
  }, [closeSidebar])

  useEffect(() => {
    if (!user || !userUploaded) {
      showLoader()
    }

    if (user && !userUploaded) {
      hideLoader()
      applyUserToState()
    }
  }, [user, hideLoader, showLoader, applyUserToState, userUploaded])

  return (
    <div className={styles.wrapper}>
      <section className={styles.section__info}>
        <h1 className={styles.title}>Информация профиля</h1>
        <div className={styles.info}>
          <div className={styles.info__header}>
            <div className={styles.avatar}>
              {fields.avatar && fields.avatar.fileURL && (
                <img src={fields.avatar.fileURL} alt="avatar" />
              )}
              {(!fields.avatar || !fields.avatar.fileURL) && <span>{fields.name[0]}</span>}
              <div className={styles.avatar__upload}>
                <input
                  className={styles.inputfile}
                  id="avatar"
                  type="file"
                  name="avatar"
                  onChange={uploadHandler}
                  accept="image/*"
                />
                <label htmlFor="avatar"> Загрузить</label>
              </div>
            </div>
            <div className={styles.content}>
              <div className={styles.content__name}>{fields.name}</div>
              <div className={styles.content__email}>{fields.email}</div>
            </div>
          </div>
          {fields.avatar && fields.avatar.fileURL && (
            <div className={styles.actions}>
              <RectangleButton
                title="Удалить аватар"
                onClick={deleteButtonHandler}
                externalStyle={styles.delete}
                theme="whiteWithRedBorder"
              />
            </div>
          )}
          <div className={styles.info__content}>
            <div className={styles.row}>
              <Input
                label="Имя"
                type="text"
                name="name"
                value={fields.name}
                inputClassName={styles.input}
                containerClassName={styles.inputContainer}
                handleChange={changeInputHandler}
              />
              <Input
                label="Фамилия"
                type="text"
                name="last_name"
                value={fields.last_name}
                inputClassName={styles.input}
                containerClassName={styles.inputContainer}
                handleChange={changeInputHandler}
              />
            </div>
            <div className={styles.row}>
              <div className={styles.selectGroup}>
                <span className={styles.label}>Дата рождения</span>
                <div className={styles.selects}>
                  <Select
                    placeholder="День"
                    id="day"
                    items={DAYS}
                    selectedItems={fields.day}
                    selectClassName={styles.select}
                    handleSelect={selectHandler}
                    maxHeight={230}
                  />
                  <Select
                    placeholder="Месяц"
                    id="month"
                    items={MONTHS}
                    selectedItems={fields.month}
                    selectClassName={styles.select}
                    handleSelect={selectHandler}
                    maxHeight={230}
                  />
                  <Select
                    placeholder="Год"
                    id="year"
                    items={YEARS}
                    selectedItems={fields.year}
                    selectClassName={styles.select}
                    handleSelect={selectHandler}
                    maxHeight={230}
                  />
                </div>
              </div>
              <RadioButton
                label="Пол"
                items={[
                  { id: 0, name: 'Мужской', value: 'male' },
                  { id: 1, name: 'Женский', value: 'female' },
                ]}
                radioClassName={styles.radio}
                containerClassName={styles.radioContainer}
                selectedItem={fields.sex}
                handleChange={changeRadioButtonHandler}
              />
            </div>
          </div>
          <div className={styles.buttons}>
            <RectangleButton
              title="Сохранить изменения"
              externalStyle={classNames(styles.buttonSubmit, { [styles.disabled]: !touchFields })}
              disabled={!touchFields}
              onClick={submitHandler}
            />
            {touchFields && (
              <RectangleButton
                title="Отменить"
                theme="whiteWithGreenBorder"
                externalStyle={styles.buttonCancel}
                onClick={cancelHandler}
              />
            )}
          </div>
        </div>
      </section>
      <section className={styles.section__security}>
        <h1 className={styles.title}>Вход и безопасность</h1>
        <div className={styles.security__email}>
          <div className={styles.security__subtitle}>Адрес эл. почты</div>
          <p>
            Ваш основной адрес эл. почты — единственный, с которым вы можете входить в систему.
            Удалить основной адрес эл. почты невозможно
          </p>
          <span>{fields.email}</span>
        </div>
        <div className={styles.security__password}>
          <div className={styles.security__subtitle}>Изменить пароль</div>
          <div className={styles.fullRow}>
            <Input
              label="Старый пароль"
              type="password"
              name="password"
              value={fields.password}
              inputClassName={styles.input}
              containerClassName={styles.inputContainer}
              handleChange={changeInputHandler}
              sizePasswordIcon={20}
            />
          </div>
          <div className={styles.fullRow}>
            <Input
              label="Новый пароль"
              type="password"
              name="newPassword"
              value={fields.newPassword}
              inputClassName={styles.input}
              containerClassName={styles.inputContainer}
              handleChange={changeInputHandler}
              sizePasswordIcon={20}
            />
          </div>
          <div className={styles.fullRow}>
            <Input
              label="Повторить ввод пароля"
              type="password"
              name="confirmPassword"
              value={fields.confirmPassword}
              inputClassName={styles.input}
              containerClassName={styles.inputContainer}
              handleChange={changeInputHandler}
              sizePasswordIcon={20}
            />
          </div>
          <div className={styles.buttons}>
            <RectangleButton
              title="Изменить пароль"
              externalStyle={classNames(styles.buttonSubmit, {
                [styles.disabled]:
                  !fields.password || !fields.newPassword || !fields.confirmPassword,
              })}
              onClick={submitPasswordHandler}
              disabled={!fields.password || !fields.newPassword || !fields.confirmPassword}
            />
          </div>
        </div>
      </section>
    </div>
  )
}

Settings.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    last_name: PropTypes.string,
    birthday: PropTypes.string,
    sex: PropTypes.string,
    email: PropTypes.string,
    avatar: PropTypes.shape({
      file_name: PropTypes.string,
      file_url: PropTypes.string,
    }),
  }),
  updateUser: PropTypes.func,
  updateUserAvatar: PropTypes.func,
  updateUserCredentials: PropTypes.func,
  showLoader: PropTypes.func,
  hideLoader: PropTypes.func,
  closeSidebar: PropTypes.func,
  deleteAvatar: PropTypes.func,
}

Settings.defaultProps = {
  user: null,
  updateUser: noop,
  updateUserAvatar: noop,
  updateUserCredentials: noop,
  showLoader: noop,
  hideLoader: noop,
  closeSidebar: noop,
  deleteAvatar: noop,
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings)
