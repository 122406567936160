import React, { useEffect, useState, useCallback, useContext } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import noop from 'lodash/noop'
import classNames from 'classnames'
import { TIME_OF_DAY_NAME } from 'utils/constants'
import { parseWrapToBr } from 'utils/utils'

import ThemeContext from 'contexts/ThemeContext'
import Loader from 'components/Loader'
import Cover from 'components/Cover'
import Specifications from 'components/Specifications'
import RectangleButton from 'components/Button/RectangleButton'

import {
  actionLoadMyRecipesRequest,
  actionDeleteRecipeRequest,
  actionLoadRecipeRequest,
} from 'store/recipes/actions'
import { selectAllRecipes, selectRecipe } from 'store/recipes/selectors'

import styles from './styles.module.scss'

const mapStateToProps = state => ({
  recipes: selectAllRecipes(state),
  recipe: selectRecipe(state),
})

const mapDispatchToProps = {
  loadRecipes: actionLoadMyRecipesRequest,
  loadRecipe: actionLoadRecipeRequest,
  deleteRecipe: actionDeleteRecipeRequest,
}

function ViewRecipe({ recipes, recipe, match, history, loadRecipe, deleteRecipe }) {
  const theme = useContext(ThemeContext)
  const [stateRecipe, setRecipe] = useState(null)
  const getRecipeById = useCallback(() => {
    setRecipe(recipes.find(item => item._id === match.params.id))
  }, [match.params.id, recipes])
  const backButtonHandler = () => history.goBack()
  const editButtonHandler = () => {
    history.push(`${match.url}/edit`)
  }
  const deleteButtonHandler = () => {
    deleteRecipe({ recipeId: match.params.id, history })
  }

  useEffect(() => {
    if (recipe) {
      setRecipe(recipe)
    }
  }, [recipe, setRecipe])

  useEffect(() => {
    if (!recipes.length) {
      loadRecipe({ recipeId: match.params.id, history })
    } else {
      getRecipeById()
    }
  }, [recipes, loadRecipe, match.params.id, history, getRecipeById])

  return (
    <div className={classNames(styles.wrapper, styles[theme])}>
      {!stateRecipe && <Loader />}
      {stateRecipe && (
        <>
          <div className={styles.container}>
            <div className={styles.header}>
              <div className={styles.buttons}>
                <div>
                  <RectangleButton
                    title="Назад"
                    onClick={backButtonHandler}
                    externalStyle={styles.back}
                    theme="blueWithWhiteBorder"
                  />
                </div>
                <div>
                  <RectangleButton
                    title="Редактировать"
                    onClick={editButtonHandler}
                    externalStyle={styles.edit}
                    theme="blueWithWhiteBorder"
                  />
                  <RectangleButton
                    title="Удалить"
                    onClick={deleteButtonHandler}
                    externalStyle={styles.delete}
                    theme="whiteWithRedBorder"
                  />
                </div>
              </div>
            </div>
            <div className={styles.titleWrapper}>
              <h1 className={styles.title}>{stateRecipe.title}</h1>
            </div>
            <div className={styles.body}>
              <div className={styles.row}>
                <div className={styles.col}>
                  <Cover cover={stateRecipe.cover} coverUrl={stateRecipe.coverUrl} />
                </div>
                <div className={styles.col}>
                  <div className={styles.field}>
                    <span>Категория:</span>
                    <span>{stateRecipe.category}</span>
                  </div>
                  <div className={styles.field}>
                    <span>География блюд:</span>
                    <span>{stateRecipe.country}</span>
                  </div>
                  <div className={styles.field}>
                    <span>Ссылка на источник:</span>
                    <span>
                      <a href={stateRecipe.link} target="_blank" rel="noopener noreferrer">
                        {stateRecipe.link}
                      </a>
                    </span>
                  </div>
                  {stateRecipe.group && (
                    <div className={styles.field}>
                      <span>Группа:</span>
                      <span>{stateRecipe.group}</span>
                    </div>
                  )}
                  <div className={styles.field}>
                    <span>Подходит на:</span>
                    <span>
                      {stateRecipe.time_of_day.map((item, index) => (
                        <span key={item}>
                          {`${TIME_OF_DAY_NAME[item]}${
                            stateRecipe.time_of_day.length - 1 === index ? '' : ', '
                          }`}
                        </span>
                      ))}
                    </span>
                  </div>
                  <div className={styles.field}>
                    <Specifications
                      selectedItems={stateRecipe.specifications}
                      labelClassName={styles.fieldTitle}
                      containerClassName={styles.specContainer}
                      isView
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.container}>
            <div className={styles.field}>
              <span>Описание:</span>
              <span
                className={styles.description}
                dangerouslySetInnerHTML={{ __html: parseWrapToBr(stateRecipe.description) }}
              />
            </div>
          </div>
          {stateRecipe.link.includes('youtube') && (
            <div className={styles.container}>
              <iframe
                title={stateRecipe.title}
                src={`https://www.youtube.com/embed/${stateRecipe.link.split('=')[1]}`}
                frameBorder="0"
                allowFullScreen
              />
            </div>
          )}
        </>
      )}
    </div>
  )
}

ViewRecipe.propTypes = {
  recipes: PropTypes.arrayOf(PropTypes.shape({})),
  recipe: PropTypes.shape({}),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
    url: PropTypes.string,
  }),
  history: PropTypes.shape({
    goBack: PropTypes.func,
    push: PropTypes.func,
  }),
  loadRecipe: PropTypes.func,
  deleteRecipe: PropTypes.func,
}

ViewRecipe.defaultProps = {
  recipes: [],
  recipe: null,
  match: {},
  history: {},
  loadRecipe: noop,
  deleteRecipe: noop,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewRecipe))
