import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import noop from 'lodash/noop'

import Input from 'components/Input'
import RectangleButton from 'components/Button/RectangleButton'
import GroupSettingsItem from 'components/GroupSettingsItem'

import { selectGroupsEntities } from 'store/groups/selectors'
import {
  actionDeleteGroupRequest,
  actionCreateGroupRequest,
  actionUpdateGroupRequest,
} from 'store/groups/actions'

import styles from './styles.module.scss'

const mapStateToProps = state => ({
  groups: selectGroupsEntities(state),
})

const mapDispatchToProps = {
  deleteGroup: actionDeleteGroupRequest,
  addGroup: actionCreateGroupRequest,
  updateGroup: actionUpdateGroupRequest,
}

function GroupSettings({ groups, deleteGroup, addGroup, updateGroup }) {
  const [groupName, setGroupName] = useState('')
  const [editGroupId, setEditGroupId] = useState(null)

  const changeInputHandler = e => {
    setGroupName(e.target.value)
  }

  const addGroupHandler = useCallback(() => {
    if (groupName.trim()) {
      addGroup({ title: groupName })
      setGroupName('')
    }
  }, [addGroup, groupName])

  const updateHandler = useCallback(
    group => {
      updateGroup([group])
    },
    [updateGroup]
  )

  const sortUpdateHandler = useCallback(
    (id, typeSorting) => {
      if (typeSorting === 'prev') {
        const index = groups.findIndex(g => g._id === id)
        const movedGroup = groups[index]
        const prevGroup = groups[index - 1]

        updateGroup([
          { id, sort_index: prevGroup.sort_index },
          { id: prevGroup._id, sort_index: movedGroup.sort_index },
        ])
      } else {
        const index = groups.findIndex(g => g._id === id)
        const movedGroup = groups[index]
        const nextGroup = groups[index + 1]

        updateGroup([
          { id, sort_index: nextGroup.sort_index },
          { id: nextGroup._id, sort_index: movedGroup.sort_index },
        ])
      }
    },
    [groups, updateGroup]
  )

  const deleteHandler = useCallback(
    id => {
      deleteGroup({ id })
    },
    [deleteGroup]
  )

  const editHandler = useCallback(id => {
    setEditGroupId(id)
  }, [])

  return (
    <div className={styles.wrapper}>
      <h1>Настройка групп</h1>
      <ul className={styles.list}>
        {groups.map((group, index) => (
          <GroupSettingsItem
            key={group._id}
            group={group}
            onEdit={editHandler}
            onDelete={deleteHandler}
            onUpdate={updateHandler}
            onSortUpdate={sortUpdateHandler}
            isEdit={editGroupId === group._id}
            isFirst={index === 0}
            isLast={index === groups.length - 1}
          />
        ))}
      </ul>
      <div className={styles.add}>
        <Input
          id="add_group"
          placeholder="Добавить группу"
          type="text"
          value={groupName}
          inputClassName={styles.input}
          containerClassName={styles.inputContainer}
          handleChange={changeInputHandler}
        />
        <RectangleButton
          title="Добавить"
          onClick={addGroupHandler}
          externalStyle={styles.button}
          theme="green"
        />
      </div>
    </div>
  )
}

GroupSettings.propTypes = {
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      sort_index: PropTypes.number,
    })
  ),
  deleteGroup: PropTypes.func,
  addGroup: PropTypes.func,
  updateGroup: PropTypes.func,
}

GroupSettings.defaultProps = {
  groups: [],
  deleteGroup: noop,
  addGroup: noop,
  updateGroup: noop,
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupSettings)
