import { takeEvery, call, select, put } from 'redux-saga/effects'
import { toastr } from 'react-redux-toastr'
import omit from 'lodash/omit'
import uniqBy from 'lodash/uniqBy'
import {
  createRecipe,
  createRecipeCover,
  loadMyRecipes,
  loadRecipe,
  deleteRecipe,
  updateFavoriteRecipe,
  updateRecipe,
  updateRecipeCover,
} from 'api/recipe'
import { selectUserId } from 'store/auth/selectors'
import { actionShowLoading, actionHideLoading } from 'store/ui/actions'
import {
  actionCreateRecipeRequest,
  actionLoadMyRecipesRequest,
  actionLoadMyRecipesSuccess,
  actionLoadRecipeRequest,
  actionLoadRecipeSuccess,
  actionDeleteRecipeRequest,
  actionUpdateFavoriteRecipeRequest,
  actionUpdateRecipeRequest,
} from './actions'
import { selectAllRecipes } from './selectors'

function* createRecipeSaga({ payload: { fields, file, history } }) {
  yield put(actionShowLoading())

  const resultCover = file ? yield call(createRecipeCover, file) : {}

  if (resultCover.error) {
    toastr.error(resultCover.error.message || 'Не удалось загрузить обложку')
    yield put(actionHideLoading())
    return
  }

  const result = yield call(createRecipe, {
    ...fields,
    ...(resultCover.data ? { cover: { ...resultCover.data } } : {}),
  })

  if (result.error) {
    toastr.error(result.error.message || 'Не удалось создать рецепт')
    yield put(actionHideLoading())
    return
  }

  const recipes = yield select(selectAllRecipes)

  yield put(actionLoadMyRecipesSuccess([...recipes, result.data]))
  yield put(actionHideLoading())
  history.push(`/recipe/${result.data._id}`)
}

function* loadMyRecipesSaga() {
  yield put(actionShowLoading())
  const userId = yield select(selectUserId)
  const result = yield call(loadMyRecipes, userId)

  if (result.error) {
    toastr.error('Не удалось загрузить рецепты')
    yield put(actionHideLoading())
    return
  }

  yield put(actionLoadMyRecipesSuccess(result.data))
  yield put(actionHideLoading())
}

function* loadRecipeSaga({ payload: { recipeId, history } }) {
  yield put(actionShowLoading())
  const result = yield call(loadRecipe, recipeId)

  if (result.error) {
    toastr.error(result.error.message || 'Рецепт не найден')
    yield put(actionHideLoading())
    history.push('/')
    return
  }

  yield put(actionHideLoading())
  yield put(actionLoadRecipeSuccess(result.data))
}

function* deleteRecipeSaga({ payload: { recipeId, history } }) {
  yield put(actionShowLoading())
  const result = yield call(deleteRecipe, recipeId)

  if (result.error) {
    toastr.error('Не удалось удалить рецепт')
    yield put(actionHideLoading())
    return
  }

  yield put(actionHideLoading())
  toastr.success('Рецепт успешно удален')
  history.push('/')
}

function* updateFavoriteRecipeSaga({ payload }) {
  const { id } = payload
  const data = omit(payload, ['id'])
  const result = yield call(updateFavoriteRecipe, id, data)

  if (result.error) {
    toastr.error(result.error.message || 'Не удалось обновить рецепт')
    yield put(actionHideLoading())
    return
  }

  const recipes = yield select(selectAllRecipes)
  const updatedRecipes = recipes.map(recipe =>
    recipe._id === id ? { ...recipe, isFavorites: data.isFavorites } : recipe
  )

  yield put(actionLoadMyRecipesSuccess(updatedRecipes))
}

function* updateRecipeSaga({ payload }) {
  const { recipe, file } = payload

  const resultCover = file ? yield call(updateRecipeCover, recipe._id, file) : {}

  if (resultCover.error) {
    toastr.error(resultCover.error.message || 'Не удалось загрузить обложку')
    yield put(actionHideLoading())
    return
  }

  const data = {
    ...omit(recipe, ['_id', '__v', 'user_id']),
    ...(resultCover.data ? { cover: { ...resultCover.data } } : {}),
    ...(resultCover.data ? { coverUrl: '' } : {}),
  }
  const result = yield call(updateRecipe, recipe._id, data)

  if (result.error) {
    toastr.error(result.error.message || 'Не удалось обновить рецепт')
    yield put(actionHideLoading())
    return
  }

  const recipes = yield select(selectAllRecipes)

  yield put(actionLoadMyRecipesSuccess(uniqBy([result.data, ...recipes], '_id')))
  yield put(actionLoadRecipeSuccess(result.data))
}

function* watchCreateRecipe() {
  yield takeEvery(actionCreateRecipeRequest, createRecipeSaga)
}

function* watchLoadMyRecipes() {
  yield takeEvery(actionLoadMyRecipesRequest, loadMyRecipesSaga)
}

function* watchLoadRicipe() {
  yield takeEvery(actionLoadRecipeRequest, loadRecipeSaga)
}

function* watchDeleteRicipe() {
  yield takeEvery(actionDeleteRecipeRequest, deleteRecipeSaga)
}

function* watchUpdateFavoriteRecipe() {
  yield takeEvery(actionUpdateFavoriteRecipeRequest, updateFavoriteRecipeSaga)
}

function* watchUpadateRecipe() {
  yield takeEvery(actionUpdateRecipeRequest, updateRecipeSaga)
}

export default [
  watchCreateRecipe(),
  watchLoadMyRecipes(),
  watchLoadRicipe(),
  watchDeleteRicipe(),
  watchUpdateFavoriteRecipe(),
  watchUpadateRecipe(),
]
