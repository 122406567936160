import { call, takeEvery, select, put } from 'redux-saga/effects'
import { toastr } from 'react-redux-toastr'
import omit from 'lodash/omit'

import {
  getUser,
  updateUser,
  updateUserAvatar,
  updateUserCredentials,
  updateUserTheme,
  recoverPassword,
  deleteUserAvatar,
} from 'api/user'
import { selectUserId } from 'store/auth/selectors'
import { actionSetTheme } from 'store/ui/actions'
import {
  actionLoadUserRequest,
  actionLoadUserSuccess,
  actionUpdateUserRequest,
  actionUpdateUserCredentialsRequest,
  actionUpdateUserAvatarRequest,
  actionRecoverPasswordRequest,
  actionUpdateUserThemeRequest,
  actionDeleteAvatarRequest,
  actionDeleteAvatarSuccess,
} from './actions'

function* loadUserSaga() {
  const userId = yield select(selectUserId)
  const result = yield call(getUser, userId)

  if (result.error) {
    toastr.error(result.error.message || 'Произошла ошибка. Попробуйте повторить позднее')
    return
  }

  yield put(actionLoadUserSuccess(result.data))
  yield put(actionSetTheme(result.data.theme))
}

function* updateUserSaga({ payload }) {
  const userId = yield select(selectUserId)
  const result = yield call(updateUser, userId, omit(payload, ['file']))

  if (result.error) {
    toastr.error(result.error.message || 'Произошла ошибка. Попробуйте повторить позднее')
    return
  }

  yield put(actionLoadUserSuccess(result.data))
  toastr.success('Данные успешно обновлены')
}

function* updateUserAvatarSaga({ payload }) {
  const userId = yield select(selectUserId)

  if (payload.file) {
    const resultFile = yield call(updateUserAvatar, userId, payload.file)

    if (resultFile.error) {
      toastr.error(resultFile.error.message || 'Не удалось загрузить аватар')
    }
  }
}

function* updateUserCredentialsSaga({ payload }) {
  const userId = yield select(selectUserId)
  const result = yield call(updateUserCredentials, userId, payload)

  if (result.error) {
    toastr.error(result.error.message || 'Произошла ошибка. Попробуйте повторить позднее')
    return
  }

  toastr.success('Пароль успешно изменен')
}

function* updateUserThemeSaga({ payload }) {
  const userId = yield select(selectUserId)
  const result = yield call(updateUserTheme, userId, { theme: payload })

  if (result.error) {
    toastr.error(result.error.message || 'Произошла ошибка. Попробуйте повторить позднее')
    return
  }

  yield put(actionLoadUserSuccess(result.data))
}

function* recoverPasswordSaga({ payload }) {
  const { email, history } = payload
  const result = yield call(recoverPassword, email)

  if (result.error) {
    toastr.error(result.error.message || 'Произошла ошибка. Попробуйте повторить позднее')
    return
  }

  toastr.success('Пароль успешно выслан')
  history.push('/login')
}

function* deleteAvatarSaga() {
  const userId = yield select(selectUserId)
  const result = yield call(deleteUserAvatar, userId)

  if (result.error) {
    toastr.error(result.error.message || 'Произошла ошибка. Попробуйте повторить позднее')
    return
  }

  yield put(actionDeleteAvatarSuccess())
  toastr.success('Аватар успешно удален')
}

function* watchLoadUser() {
  yield takeEvery(actionLoadUserRequest, loadUserSaga)
}

function* watchUpdateUser() {
  yield takeEvery(actionUpdateUserRequest, updateUserSaga)
}

function* watchUpdateUserAvatar() {
  yield takeEvery(actionUpdateUserAvatarRequest, updateUserAvatarSaga)
}

function* watchUpdateUserCredentials() {
  yield takeEvery(actionUpdateUserCredentialsRequest, updateUserCredentialsSaga)
}

function* watchUpdateUserTheme() {
  yield takeEvery(actionUpdateUserThemeRequest, updateUserThemeSaga)
}

function* watchRecoverPassword() {
  yield takeEvery(actionRecoverPasswordRequest, recoverPasswordSaga)
}

function* watchDeleteAvatarSaga() {
  yield takeEvery(actionDeleteAvatarRequest, deleteAvatarSaga)
}

export default [
  watchLoadUser(),
  watchUpdateUser(),
  watchUpdateUserCredentials(),
  watchUpdateUserAvatar(),
  watchUpdateUserTheme(),
  watchRecoverPassword(),
  watchDeleteAvatarSaga(),
]
