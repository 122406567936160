import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import noop from 'lodash/noop'
import { Formik } from 'formik'

import LogoText from 'icons/logoText'
import Email from 'icons/email'
import Password from 'icons/password'
import Input from 'components/Input'
import RectangleButton from 'components/Button/RectangleButton'

import { actionAuthRequest } from 'store/auth/actions'

import { getValidationSchema } from './validation'
import { initialValues } from './utils'
import styles from './styles.module.scss'

const mapDispatchToProps = {
  onLogin: actionAuthRequest,
}

const Login = ({ onLogin, history }) => {
  const changeHandler = (setFieldValue, setFieldTouched) => event => {
    const { value, name } = event.target
    setFieldValue(name, value)
    setFieldTouched(name, true, false)
  }
  const loginHandler = values => {
    onLogin(values, history)
  }
  const keyPressHandler = (handler, values) => e => {
    if (e.key === 'Enter') {
      handler(values)
    }
  }

  return (
    <div className={styles.login}>
      <div className={styles.login__container}>
        <div className={styles.login__form}>
          <div className={styles.form__logo}>
            <Link to="/">
              <LogoText size={200} />
            </Link>
          </div>
          <Formik
            initialValues={initialValues()}
            validationSchema={getValidationSchema()}
            onSubmit={loginHandler}
          >
            {({
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              submitCount,
              values,
              errors,
              touched,
            }) => (
              <>
                <div className={styles.form__inputs}>
                  <div className={styles.inputWrapper}>
                    <Input
                      placeholder="Email"
                      type="text"
                      name="email"
                      id="email"
                      value={values.email}
                      inputClassName={styles.input}
                      containerClassName={styles.email}
                      icon={<Email />}
                      handleChange={changeHandler(setFieldValue, setFieldTouched)}
                      handleKeyPress={keyPressHandler(handleSubmit, values)}
                      errors={errors}
                      touched={touched}
                      submitCount={submitCount}
                    />
                  </div>
                  <div className={styles.inputWrapper}>
                    <Input
                      placeholder="Пароль"
                      type="password"
                      name="password"
                      id="password"
                      value={values.password}
                      inputClassName={styles.input}
                      containerClassName={styles.password}
                      icon={<Password />}
                      handleChange={changeHandler(setFieldValue, setFieldTouched)}
                      handleKeyPress={keyPressHandler(handleSubmit, values)}
                      errors={errors}
                      touched={touched}
                      submitCount={submitCount}
                    />
                  </div>
                </div>
                <div className={styles.forgot}>
                  Забыли пароль?
                  <Link to="/recover">
                    <span>Восстановить</span>
                  </Link>
                </div>
                <RectangleButton
                  title="Войти"
                  externalStyle={styles.loginButtons}
                  onClick={handleSubmit}
                  type="submit"
                />
              </>
            )}
          </Formik>
        </div>
        <div className={styles.registration}>
          У вас еще нет аккаунта? <Link to="/registration">Начать</Link>
        </div>
      </div>
    </div>
  )
}

Login.propTypes = {
  onLogin: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
}

Login.defaultProps = {
  onLogin: noop,
  history: noop,
}

export default withRouter(connect(null, mapDispatchToProps)(Login))
