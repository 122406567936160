import * as yup from 'yup'

export const getValidationSchema = () =>
  yup.object({
    email: yup
      .string()
      .trim()
      .email('Неверный формат Email')
      .required('Поле "Email" обязательно к заполнению'),
    password: yup
      .string()
      .trim()
      .min(6, 'Поле "Пароль" должно содержать более 5 символов')
      .required('Поле "Пароль" обязательно к заполнению'),
  })
