import React from 'react'
import PropTypes from 'prop-types'

const Spicy = ({ size, color }) => (
  <svg
    width={(24 * size) / 25}
    height={size}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5002 12.5837V8.37811L13.0275 7.24279C10.2863 10.5044 9.26156 19 2.625 19C1.92881 19 1.26113 19.2765 0.768845 19.7688C0.276562 20.2611 0 20.9288 0 21.625C0 22.3212 0.276562 22.9889 0.768845 23.4811C1.26113 23.9734 1.92881 24.25 2.625 24.25C12.5925 24.25 19.9992 18.4904 22.2741 14.3903L20.5373 12.5837H15.5002ZM21.645 6.56639C23.1525 3.76139 22.3678 1.49123 21.4978 0.390605C21.4646 0.349444 21.4231 0.315765 21.376 0.29178C21.3289 0.267795 21.2772 0.254046 21.2244 0.251434C21.1716 0.248823 21.1188 0.257408 21.0696 0.276626C21.0203 0.295845 20.9757 0.325263 20.9386 0.362949L19.8652 1.44107C19.8035 1.50369 19.7657 1.58582 19.758 1.67334C19.7504 1.76085 19.7736 1.84829 19.8234 1.9206C20.145 2.40623 20.6227 3.56592 19.7578 5.31061C18.9128 4.94122 18.0006 4.75036 17.0784 4.74998C15.7659 4.74998 14.5472 5.15029 13.5 5.80889L17.0002 7.41623V11.0837H21.1763L23.7394 13.75C23.8922 13.1584 24 12.5486 24 11.9073C24.0009 10.9022 23.7919 9.90792 23.3864 8.98821C22.9809 8.0685 22.3877 7.24362 21.645 6.56639Z"
      fill={color}
    />
  </svg>
)

Spicy.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

Spicy.defaultProps = {
  size: 25,
  color: 'currentColor',
}

export default Spicy
