import React from 'react'
import PropTypes from 'prop-types'

const HeartHover = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.8125 10.3594C24.2344 3.42188 15.7969 6.51563 15 9.98438C13.9219 6.28126 5.71875 3.51563 3.1875 10.3594C0.374998 17.9531 14.0156 24.75 15 25.875C15.9844 24.9844 29.625 17.8125 26.8125 10.3594Z"
      fill={color}
    />
    <path
      d="M28.125 8.67187C27.8438 7.78125 27.375 6.98437 26.7188 6.32812C26.1094 5.67187 25.3125 5.15625 24.5156 4.82812C24.0938 4.64062 23.6719 4.54687 23.25 4.45312C23.0156 4.40625 22.8281 4.40625 22.5938 4.35938H21.9375L22.5 4.64062L23.0625 4.92187C23.4375 5.10937 23.8125 5.29688 24.1406 5.53125C24.8438 5.95312 25.4531 6.46875 26.0156 7.03125C26.5781 7.59375 27 8.25 27.4219 9C27.6094 9.375 27.7969 9.75 27.9375 10.125C28.0313 10.3125 28.0781 10.5469 28.1719 10.7344L28.4063 11.3438C28.4531 11.1094 28.4531 10.875 28.4531 10.6875C28.4531 10.4531 28.4531 10.2187 28.4063 10.0312C28.3594 9.5625 28.2656 9.09375 28.125 8.67187"
      fill={color}
    />
    <path
      d="M29.0625 7.40625C29.0156 7.07813 28.9687 6.75 28.875 6.42188C28.6875 5.76563 28.3125 5.15625 27.8437 4.64062C27.375 4.125 26.8125 3.75 26.2031 3.5625C25.875 3.46875 25.5937 3.375 25.2656 3.32812C25.125 3.32812 24.9375 3.28125 24.7969 3.28125C24.6562 3.28125 24.4687 3.28125 24.3281 3.32812C24.4687 3.42187 24.6094 3.46875 24.75 3.5625C24.8437 3.60937 24.9844 3.70313 25.125 3.75C25.4062 3.89063 25.6406 4.03125 25.875 4.21875C26.3437 4.54688 26.8125 4.92188 27.1875 5.29688C27.5625 5.71875 27.8906 6.1875 28.2187 6.70313C28.3594 6.9375 28.5 7.21875 28.6406 7.5C28.6875 7.64063 28.7812 7.78125 28.8281 7.92188C28.875 8.0625 28.9687 8.20313 29.0156 8.39063C29.0625 8.20313 29.0625 8.0625 29.0625 7.875V7.40625"
      fill={color}
    />
    <path
      d="M2.01563 10.125C2.15625 9.75 2.34375 9.32813 2.53125 9C2.90625 8.25 3.375 7.59375 3.9375 7.03125C4.5 6.46875 5.10937 5.95312 5.8125 5.53125C6.14062 5.29688 6.51562 5.10937 6.89062 4.92187L7.45312 4.64062L8.01563 4.35938H7.35937C7.125 4.35938 6.9375 4.40625 6.70312 4.45312C6.28125 4.54687 5.85938 4.64062 5.4375 4.82812C4.64063 5.15625 3.89062 5.67187 3.28125 6.32812C2.67188 6.98437 2.15625 7.78125 1.875 8.67187C1.73438 9.09375 1.64063 9.5625 1.59375 9.98437C1.54688 10.2187 1.54688 10.4531 1.54688 10.6406C1.54688 10.875 1.54688 11.1094 1.59375 11.2969L1.82813 10.6875C1.875 10.5 1.92188 10.3125 2.01563 10.125"
      fill={color}
    />
    <path
      d="M2.8125 5.34375C3.1875 4.92187 3.65625 4.54687 4.125 4.21875C4.35938 4.07812 4.64062 3.89062 4.875 3.75C5.01562 3.65625 5.15625 3.60937 5.29688 3.51562C5.4375 3.42187 5.57812 3.375 5.71875 3.28125C5.57812 3.28125 5.39062 3.23438 5.25 3.23438C5.10937 3.23438 4.92187 3.23437 4.78125 3.28125C4.45312 3.32812 4.17187 3.42187 3.84375 3.51562C3.1875 3.79687 2.625 4.17187 2.15625 4.64062C1.6875 5.15625 1.3125 5.76562 1.125 6.42187C1.03125 6.75 0.984375 7.07812 0.9375 7.40625V7.92187C0.9375 8.10937 0.9375 8.25 0.984375 8.4375C1.03125 8.29687 1.125 8.15625 1.17188 7.96875C1.26563 7.78125 1.3125 7.64062 1.40625 7.5C1.54688 7.21875 1.6875 6.9375 1.82813 6.70312C2.10938 6.1875 2.4375 5.71875 2.8125 5.34375"
      fill={color}
    />
    <path
      d="M3.65625 18.9844L3.60938 19.0781C3.84375 19.7813 4.21875 20.3437 4.59375 20.9062C4.96875 21.4687 5.4375 21.9375 5.90625 22.3594C6.375 22.7813 6.89062 23.2031 7.45312 23.5781C8.01562 23.9531 8.625 24.2344 9.32812 24.4219L9.375 24.3281C8.4375 23.3906 7.5 22.5 6.5625 21.6094C5.57813 20.7656 4.64063 19.8281 3.65625 18.9844"
      fill={color}
    />
    <path
      d="M1.6875 20.3906L1.64062 20.4844C1.96875 21.2813 2.39062 21.9375 2.85937 22.5469C3.32812 23.1562 3.84375 23.7188 4.40625 24.2344C4.96875 24.75 5.53125 25.2187 6.1875 25.6875C6.84375 26.1094 7.5 26.5312 8.29688 26.7656L8.34375 26.6719C7.26562 25.5938 6.14063 24.5625 5.0625 23.4844C3.9375 22.4531 2.8125 21.375 1.6875 20.3906"
      fill={color}
    />
    <path
      d="M24.1406 22.3594C24.6094 21.8906 25.0781 21.4219 25.4531 20.9062C25.8281 20.3906 26.2031 19.7813 26.4375 19.0781L26.3906 18.9844C25.4063 19.8281 24.4687 20.7656 23.4844 21.6094C22.5469 22.5 21.6094 23.3906 20.6719 24.3281L20.7188 24.4219C21.4219 24.2344 22.0313 23.9062 22.5938 23.5781C23.1563 23.2031 23.625 22.7813 24.1406 22.3594"
      fill={color}
    />
    <path
      d="M24.9375 23.4844C23.8594 24.5625 22.7344 25.5938 21.6562 26.6719L21.7031 26.7656C22.5 26.4844 23.1562 26.1094 23.8125 25.6875C24.4687 25.2656 25.0312 24.7969 25.5937 24.2344C26.1562 23.7188 26.6719 23.1562 27.1406 22.5469C27.6094 21.9375 28.0312 21.2813 28.3594 20.4844L28.3125 20.3906C27.1875 21.375 26.0625 22.4531 24.9375 23.4844"
      fill={color}
    />
  </svg>
)

HeartHover.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

HeartHover.defaultProps = {
  size: 30,
  color: 'currentColor',
}

export default HeartHover
