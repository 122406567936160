import React from 'react'
import PropTypes from 'prop-types'

const Bug = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.3944 8.4375V7.34625C20.3944 5.89518 19.8179 4.50355 18.7919 3.47749C17.7658 2.45143 16.3742 1.875 14.9231 1.875C13.4721 1.875 12.0804 2.45143 11.0544 3.47749C10.0283 4.50355 9.45187 5.89518 9.45187 7.34625V8.4375H7.89L4.77188 5.30437L3.66 6.41062L6.68062 9.44625L6.645 9.53812C6.01382 11.2363 5.69433 13.0345 5.70187 14.8463C5.70187 15.2138 5.715 15.5775 5.73938 15.9338L5.745 16.0181H1.875V17.5856H5.94187L5.95312 17.6494C6.27562 19.4138 6.89813 21.0281 7.74188 22.395L7.80563 22.4981L4.125 26.1787L5.235 27.2887L8.74313 23.7788L8.85188 23.9025C10.4794 25.7625 12.6056 26.8819 14.9231 26.8819C17.2069 26.8819 19.3031 25.7963 20.9212 23.9869L21.0281 23.8669L24.6094 27.4669L25.7213 26.3587L21.9788 22.5975L22.0444 22.4925C22.92 21.105 23.5631 19.4569 23.8931 17.6494L23.9044 17.5856H27.975V16.0181H24.1031L24.1087 15.9356C24.1337 15.5737 24.1462 15.2109 24.1463 14.8481C24.1538 13.0127 23.8252 11.1915 23.1769 9.47437L23.1413 9.38062L26.085 6.43688L24.975 5.33062L21.8662 8.4375H20.3944ZM11.0194 8.4375V7.34625C11.0194 6.31091 11.4307 5.31798 12.1628 4.58588C12.8949 3.85379 13.8878 3.4425 14.9231 3.4425C15.9585 3.4425 16.9514 3.85379 17.6835 4.58588C18.4156 5.31798 18.8269 6.31091 18.8269 7.34625V8.4375H11.0212H11.0194ZM21.7013 10.0069L21.7387 10.1062C22.2694 11.5181 22.5769 13.125 22.5769 14.8463C22.5769 17.865 21.6338 20.535 20.1881 22.4156C18.7444 24.2925 16.8675 25.3125 14.9231 25.3125C12.9806 25.3125 11.1037 24.2925 9.66 22.4156C8.2125 20.535 7.26938 17.865 7.26938 14.8463C7.26938 13.125 7.57687 11.5181 8.10937 10.1062L8.14687 10.0069H21.7013V10.0069Z"
      fill={color}
    />
  </svg>
)

Bug.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

Bug.defaultProps = {
  size: 30,
  color: 'currentColor',
}

export default Bug
