import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import intersection from 'lodash/intersection'

import { ROUTES_TITLE } from 'routes'

import Card from 'components/Card'
import Pencil from 'icons/pancil'

import { selectSearchString, selectFilterParams } from 'store/filters/selectors'

import styles from './styles.module.scss'

const mapStateToProps = state => ({
  searchString: selectSearchString(state),
  filterParams: selectFilterParams(state),
})

function CardsList({ list, history, onFavoriteClick, searchString, filterParams }) {
  const cardClickHandler = id => () => history.push(`/recipe/${id}`)
  const filterByParams = list => {
    let filtredList = [...list]

    Object.keys(filterParams).forEach(key => {
      if (!filterParams[key].length) return

      if (key === 'categories') {
        filtredList = filtredList.filter(recipe => filterParams[key].includes(recipe.category))
      }

      if (key === 'countries') {
        filtredList = filtredList.filter(recipe => filterParams[key].includes(recipe.country))
      }

      if (key === 'specifications') {
        filtredList = filtredList.filter(
          recipe => !!intersection(recipe.specifications, filterParams[key]).length
        )
      }

      if (key === 'timeOfDay') {
        filtredList = filtredList.filter(
          recipe => !!intersection(recipe.time_of_day, filterParams[key]).length
        )
      }

      if (key === 'group') {
        filtredList = filtredList.filter(recipe => filterParams[key] === recipe.group)
      }
    })

    return searchString
      ? filtredList.filter(recipe =>
          recipe.title.toLowerCase().includes(searchString.toLowerCase())
        )
      : filtredList
  }

  const addHandler = useCallback(() => {
    history.push('/new_recipe')
  }, [history])

  const getTitle = useCallback(() => {
    return ROUTES_TITLE[history.location.pathname]
  }, [history])

  return (
    <>
      <div className={styles.header}>
        <div className={styles.title}>
          {getTitle()}
          {filterParams.group && <span>Группа: {filterParams.group}</span>}
        </div>
        <div className={styles.add} onClick={addHandler}>
          <Pencil />
          <span>Добавить рецепт</span>
        </div>
      </div>
      <div className={styles.cards}>
        {filterByParams(list).map(recipe => (
          <Card
            key={recipe._id}
            recipe={recipe}
            onClick={cardClickHandler}
            onFavoriteClick={onFavoriteClick}
          />
        ))}
      </div>
    </>
  )
}

CardsList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({})),
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }),
  onFavoriteClick: PropTypes.func,
  filterParams: PropTypes.shape({}),
  searchString: PropTypes.string,
}

CardsList.defaultProps = {
  list: [],
  history: {},
  onFavoriteClick: noop,
  filterParams: {},
  searchString: '',
}

export default withRouter(connect(mapStateToProps, null)(CardsList))
