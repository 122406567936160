import { takeEvery, call, put } from 'redux-saga/effects'
import { toastr } from 'react-redux-toastr'
import { loadCountries } from 'api/countries'
import { loadCategories } from 'api/categories'

import { actionLoadGroupsRequest } from 'store/groups/actions'

import {
  actionLoadDataRequest,
  actionLoadCountriesRequest,
  actionLoadCountriesSuccess,
  actionLoadCategoriesRequest,
  actionLoadCategoriesSuccess,
} from './actions'

function* loadData() {
  yield call(loadCountriesSaga)
  yield call(loadCategoriesSaga)
  yield put(actionLoadGroupsRequest())
}

function* loadCountriesSaga() {
  const result = yield call(loadCountries)

  if (result.error) {
    toastr.error(result.error.message || 'Произошла ошибка. Попробуйте повторить позднее')
    return
  }

  yield put(actionLoadCountriesSuccess(result.data))
}

function* loadCategoriesSaga() {
  const result = yield call(loadCategories)

  if (result.error) {
    toastr.error(result.error.message || 'Произошла ошибка. Попробуйте повторить позднее')
    return
  }

  yield put(actionLoadCategoriesSuccess(result.data))
}

function* watchLoadData() {
  yield takeEvery(actionLoadDataRequest, loadData)
}

function* watchLoadCountries() {
  yield takeEvery(actionLoadCountriesRequest, loadCountriesSaga)
}

function* watchLoadCategories() {
  yield takeEvery(actionLoadCategoriesRequest, loadCategoriesSaga)
}

export default [watchLoadData(), watchLoadCountries(), watchLoadCategories()]
