import React, { useContext } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import noop from 'lodash/noop'
import { TIME_OF_DAY, SPECIFICATIONS } from 'utils/constants'

import FilterItem from 'components/FilterItem'
import RectangleButton from 'components/Button/RectangleButton'
import Category from 'icons/category'
import Kitchen from 'icons/kitchen'
import Specifications from 'icons/specifications'
import Clock from 'icons/clock'
import ThemeContext from 'contexts/ThemeContext'

import { actionChangeFilterParams, actionResetFilterParams } from 'store/filters/actions'
import { selectFilterParams } from 'store/filters/selectors'

import styles from './styles.module.scss'

const mapStateToProps = state => ({
  filterParams: selectFilterParams(state),
})

const mapDispatchToProps = {
  changeFilterParams: actionChangeFilterParams,
  resetFilterParams: actionResetFilterParams,
}

const Filter = ({ categories, countries, changeFilterParams, filterParams, resetFilterParams }) => {
  const theme = useContext(ThemeContext)
  const changeHandler = (value, name, isSelected) => {
    changeFilterParams({ key: name, value: value.value || value.name, isSelected })
  }
  const resetFilterHandler = () => {
    resetFilterParams()
  }

  return (
    <div className={styles.filter}>
      <div className={classNames(styles.header, styles[theme])}>
        <div className={styles.filter__title}>
          <span>ФИЛЬТРЫ</span>
        </div>
        <RectangleButton
          title="Сбросить"
          onClick={resetFilterHandler}
          externalStyle={styles.reset}
          theme="whiteSimple"
          disabled={
            !filterParams.categories.length &&
            !filterParams.countries.length &&
            !filterParams.specifications.length &&
            !filterParams.timeOfDay.length
          }
        />
      </div>
      <FilterItem
        title="Категория блюд"
        name="categories"
        items={categories}
        selectedItems={filterParams.categories}
        onChange={changeHandler}
        icon={<Category size={15} />}
      />
      <FilterItem
        title="Кухни"
        name="countries"
        items={countries}
        selectedItems={filterParams.countries}
        onChange={changeHandler}
        icon={<Kitchen size={15} />}
      />
      <FilterItem
        title="Характеристики"
        name="specifications"
        items={SPECIFICATIONS}
        selectedItems={filterParams.specifications}
        onChange={changeHandler}
        icon={<Specifications size={19} />}
      />
      <FilterItem
        title="Время приема пищи"
        name="timeOfDay"
        items={TIME_OF_DAY}
        selectedItems={filterParams.timeOfDay}
        onChange={changeHandler}
        icon={<Clock size={15} />}
      />
    </div>
  )
}

Filter.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({})),
  countries: PropTypes.arrayOf(PropTypes.shape({})),
  filterParams: PropTypes.shape({
    categories: PropTypes.arrayOf(PropTypes.string),
    countries: PropTypes.arrayOf(PropTypes.string),
    specifications: PropTypes.arrayOf(PropTypes.string),
    timeOfDay: PropTypes.arrayOf(PropTypes.string),
  }),
  changeFilterParams: PropTypes.func,
  resetFilterParams: PropTypes.func,
}

Filter.defaultProps = {
  categories: [],
  countries: [],
  filterParams: {},
  changeFilterParams: noop,
  resetFilterParams: noop,
}

export default connect(mapStateToProps, mapDispatchToProps)(Filter)
