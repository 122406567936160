import React from 'react'
import PropTypes from 'prop-types'

const Search = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 22.5C14.7187 22.4995 16.8735 21.7568 18.6213 20.39L24.1163 25.885L25.8838 24.1175L20.3888 18.6225C21.7563 16.8746 22.4995 14.7193 22.5 12.5C22.5 6.98625 18.0138 2.5 12.5 2.5C6.98625 2.5 2.5 6.98625 2.5 12.5C2.5 18.0138 6.98625 22.5 12.5 22.5ZM12.5 5C16.6363 5 20 8.36375 20 12.5C20 16.6362 16.6363 20 12.5 20C8.36375 20 5 16.6362 5 12.5C5 8.36375 8.36375 5 12.5 5Z"
      fill={color}
    />
    <path
      d="M14.265 10.7325C14.7388 11.2075 15 11.835 15 12.5H17.5C17.5011 11.843 17.372 11.1924 17.1201 10.5856C16.8682 9.97883 16.4986 9.42802 16.0325 8.96499C14.14 7.07499 10.8588 7.07499 8.96753 8.96499L10.7325 10.735C11.6825 9.78749 13.32 9.78999 14.265 10.7325Z"
      fill={color}
    />
  </svg>
)

Search.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

Search.defaultProps = {
  size: 30,
  color: 'currentColor',
}

export default Search
