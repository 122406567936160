import React from 'react'
import PropTypes from 'prop-types'

const PancilSimple = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.2563 3.85786C15.5812 3.53291 15.5812 2.99131 15.2563 2.68302L13.3066 0.733294C12.9983 0.40834 12.4567 0.40834 12.1317 0.733294L10.5986 2.25808L13.7232 5.38265L15.2563 3.85786ZM0.5 12.365V15.4896H3.62457L12.84 6.26586L9.71539 3.14129L0.5 12.365Z"
      fill={color}
    />
  </svg>
)

PancilSimple.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

PancilSimple.defaultProps = {
  size: 15,
  color: 'currentColor',
}

export default PancilSimple
