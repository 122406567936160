import React from 'react'
import PropTypes from 'prop-types'

const EyeClose = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M18.4753 0.274922C18.3882 0.187803 18.2848 0.118694 18.171 0.0715425C18.0572 0.0243911 17.9353 0.00012207 17.8121 0.00012207C17.689 0.00012207 17.567 0.0243911 17.4532 0.0715425C17.3394 0.118694 17.2361 0.187803 17.149 0.274922L13.2003 4.22367C12.1622 3.90949 11.0835 3.75027 9.999 3.75117C5.63775 3.75117 1.8565 6.29117 -0.000999451 10.0012C0.796002 11.5935 1.95279 12.9784 3.37775 14.0462L0.272751 17.1512C0.101892 17.3279 0.00728422 17.5647 0.00930356 17.8105C0.0113229 18.0563 0.109808 18.2915 0.283547 18.4654C0.457286 18.6393 0.692377 18.738 0.938187 18.7402C1.184 18.7425 1.42086 18.6481 1.59775 18.4774L18.4728 1.60242C18.5599 1.51536 18.629 1.41198 18.6761 1.2982C18.7233 1.18442 18.7476 1.06246 18.7476 0.939297C18.7476 0.816132 18.7233 0.694174 18.6761 0.580392C18.629 0.466611 18.5599 0.363235 18.4728 0.276172L18.4753 0.274922ZM8.12525 6.24992C8.51733 6.24987 8.89955 6.37272 9.21817 6.6012C9.5368 6.82969 9.77577 7.1523 9.9015 7.52367L7.524 9.90117C7.10281 9.75879 6.74593 9.47137 6.51703 9.09021C6.28814 8.70905 6.20214 8.25896 6.27438 7.82027C6.34662 7.38157 6.5724 6.98282 6.91142 6.69518C7.25044 6.40754 7.68065 6.24974 8.12525 6.24992V6.24992ZM2.1515 9.99992C2.94574 8.74134 4.02477 7.68714 5.3015 6.92242C5.1015 7.47076 4.99953 8.05 5.00025 8.63367C5.00025 9.70617 5.33775 10.6987 5.91275 11.5124L4.72275 12.7024C3.69603 11.9797 2.82222 11.0613 2.1515 9.99992V9.99992Z"
        fill={color}
      />
      <path
        d="M15 8.63246C15 8.10246 14.9175 7.59121 14.7638 7.11121L8.47875 13.3962C9.22844 13.6357 10.0241 13.6947 10.8009 13.5687C11.5778 13.4426 12.3139 13.135 12.9494 12.6707C13.5849 12.2065 14.1018 11.5988 14.4581 10.8971C14.8145 10.1954 15.0001 9.41946 15 8.63246V8.63246Z"
        fill={color}
      />
      <path
        d="M16.2112 5.6637L14.8563 7.0187L14.93 7.06495C16.105 7.81495 17.1012 8.8187 17.8487 9.99995C17.1036 11.1811 16.107 12.1832 14.93 12.9349C13.8794 13.6063 12.7028 14.056 11.4722 14.2565C10.2417 14.457 8.98315 14.4042 7.77375 14.1012L6.2725 15.6024C7.46833 16.0317 8.72945 16.2508 10 16.2499C14.3612 16.2499 18.1425 13.7099 20 9.99995C19.1279 8.25482 17.8236 6.76201 16.2112 5.6637V5.6637Z"
        fill={color}
      />
    </g>
  </svg>
)

EyeClose.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

EyeClose.defaultProps = {
  size: 20,
  color: 'currentColor',
}

export default EyeClose
