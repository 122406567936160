import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import noop from 'lodash/noop'
import { parseDate, getCoverSrcRecipe } from 'utils/utils'

import IconLink from 'icons/link'
import Heart from 'icons/heart'
import HeartFill from 'icons/heartFill'
import HeartHover from 'icons/heartHover'

import styles from './styles.module.scss'

const Card = ({ recipe, onClick, onFavoriteClick }) => {
  const [isHovered, setIsHovered] = useState(false)
  const mouseEnterHandler = () => setIsHovered(true)
  const mouseLeaveHandler = () => setIsHovered(false)
  const renderFavouritesIcon = isFavorites => {
    if (isFavorites) {
      return <HeartFill size={20} />
    }

    if (isHovered) {
      return <HeartHover size={20} />
    }

    return <Heart size={20} />
  }
  const favoriteClickhandler = e => {
    e.stopPropagation()
    onFavoriteClick(recipe)
  }
  const footerClickHandler = e => {
    e.stopPropagation()
  }

  return (
    <div className={styles.card} onClick={onClick(recipe._id)}>
      <div
        className={classNames(styles.card__cover, {
          [styles.default]: (!recipe.cover || !recipe.cover.fileURL) && !recipe.coverUrl,
        })}
      >
        <img src={getCoverSrcRecipe(recipe)} alt={recipe.title} />
      </div>
      <div className={styles.card__content}>
        <div className={styles.card__title}>{recipe.title}</div>
        <div className={styles.card__description}>{recipe.description}</div>
        <div className={styles.card__footer} onClick={footerClickHandler}>
          <div className={styles.card__date}>{parseDate(recipe.create_at)}</div>
          <div className={styles.footer__right}>
            <a
              className={styles.card__source}
              href={recipe.link}
              target="_blank"
              rel="noopener noreferrer"
              title="Источник"
            >
              <IconLink size={15} />
            </a>
            <div
              className={classNames(styles.card_favourites, {
                [styles.selected]: recipe.isFavorites,
              })}
              onMouseEnter={mouseEnterHandler}
              onMouseLeave={mouseLeaveHandler}
              onClick={favoriteClickhandler}
              title="Добавить в избранное"
            >
              {renderFavouritesIcon(recipe.isFavorites)}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Card.propTypes = {
  recipe: PropTypes.shape({
    _id: PropTypes.string,
    coverUrl: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    create_at: PropTypes.string,
    link: PropTypes.string,
    isFavorites: PropTypes.bool,
    cover: PropTypes.shape({
      fileURL: PropTypes.string,
    }),
  }),
  onClick: PropTypes.func,
  onFavoriteClick: PropTypes.func,
}

Card.defaultProps = {
  recipe: {},
  onClick: noop,
  onFavoriteClick: noop,
}

export default Card
