import { handleActions } from 'redux-actions'
import { actionLoadCountriesSuccess, actionLoadCategoriesSuccess } from './actions'

const initialState = {
  countries: null,
  categories: null,
}

export default handleActions(
  {
    [actionLoadCountriesSuccess]: (state, { payload }) => ({
      ...state,
      countries: payload,
    }),

    [actionLoadCategoriesSuccess]: (state, { payload }) => ({
      ...state,
      categories: payload,
    }),
  },
  initialState
)
