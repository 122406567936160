import { makeRequest } from 'utils/request'

export const loadGroups = () =>
  makeRequest({
    url: '/api/groups',
  })

export const createGroup = data =>
  makeRequest({
    url: '/api/groups',
    method: 'post',
    data,
  })

export const deleteGroup = id =>
  makeRequest({
    url: `/api/groups/${id}`,
    method: 'delete',
  })

export const updateGroup = (id, data) =>
  makeRequest({
    url: `/api/groups/${id}`,
    method: 'patch',
    data,
  })
