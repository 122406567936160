import React from 'react'
import PropTypes from 'prop-types'

const Cake = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.0001 0.75C11.6631 0.75 12.299 1.01339 12.7679 1.48223C13.2367 1.95107 13.5001 2.58696 13.5001 3.25C13.5001 3.91304 13.2367 4.54893 12.7679 5.01777C12.299 5.48661 11.6631 5.75 11.0001 5.75C10.3371 5.75 9.70117 5.48661 9.23233 5.01777C8.76349 4.54893 8.5001 3.91304 8.5001 3.25C8.5001 2.58696 8.76349 1.95107 9.23233 1.48223C9.70117 1.01339 10.3371 0.75 11.0001 0.75ZM14.8701 4.25C17.0001 4.25 19.0001 6.25 19.0001 8.25C21.7001 8.25 21.7001 12.25 19.0001 12.25H3.0001C0.300098 12.25 0.300098 8.25 3.0001 8.25C3.0001 6.25 5.0001 4.25 7.1301 4.25C7.5701 5.98 9.1401 7.25 11.0001 7.25C12.8601 7.25 14.4301 5.98 14.8701 4.25ZM4.0001 14.25H7.0001L8.0001 21.25H6.0001L4.0001 14.25ZM9.0001 14.25H13.0001L12.0001 21.25H10.0001L9.0001 14.25ZM15.0001 14.25H18.0001L16.0001 21.25H14.0001L15.0001 14.25Z"
      fill={color}
    />
  </svg>
)

Cake.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

Cake.defaultProps = {
  size: 22,
  color: 'currentColor',
}

export default Cake
