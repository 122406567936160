import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import noop from 'lodash/noop'

import { MODAL_TYPES } from 'utils/constants'

import Group from './Group'
import BugReport from './BugReport'
import Close from 'icons/close'

import { selectIsModalOpened, selectModalType } from 'store/ui/selectors'
import { actionModalClose } from 'store/ui/actions'

import styles from './styles.module.scss'

const mapStateToProps = state => ({
  isOpen: selectIsModalOpened(state),
  modalType: selectModalType(state),
})

const mapDispatchToProps = {
  closeModal: actionModalClose,
}

const MODAL_TYPE_MAP = {
  [MODAL_TYPES.GROUP]: <Group />,
  [MODAL_TYPES.BUG_REPORT]: <BugReport />,
}

const Modal = ({ isOpen, modalType, closeModal }) => {
  const Component = MODAL_TYPE_MAP[modalType]

  if (Component && isOpen) {
    return (
      <div className={styles.modalWrapper}>
        <div className={styles.modal}>
          <div onClick={closeModal} className={styles.iconWrapper}>
            <Close size={24} />
          </div>
          {Component}
        </div>
        <div className={styles.overlay} onClick={closeModal} />
      </div>
    )
  }

  return null
}

const { bool, string, func } = PropTypes

Modal.propTypes = {
  isOpen: bool,
  modalType: string,
  closeModal: func,
}

Modal.defaultProps = {
  isOpen: false,
  modalType: '',
  closeModal: noop,
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal)
