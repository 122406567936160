import React from 'react'
import PropTypes from 'prop-types'

const Specifications = ({ size, color }) => (
  <svg
    width={size}
    height={(24 * size) / 31}
    viewBox="0 0 31 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29 16.5C29 9.5625 23.7395 3.8385 17 3.0885V0H14V3.0885C7.2605 3.8385 2 9.5625 2 16.5V19.5H29V16.5ZM5 16.5C5 10.7115 9.7115 6 15.5 6C21.2885 6 26 10.7115 26 16.5H5ZM0.5 21H30.5V24H0.5V21Z"
      fill={color}
    />
  </svg>
)

Specifications.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

Specifications.defaultProps = {
  size: 31,
  color: 'currentColor',
}

export default Specifications
