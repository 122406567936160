import React from 'react'
import PropTypes from 'prop-types'

const Book = ({ size, color }) => (
  <svg
    width={size}
    height={(83 * size) / 100}
    viewBox="0 0 100 83"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.0875 1.70001C30.0812 -0.299986 43.225 -0.562487 52.2125 8.41251C52.5025 8.70326 52.7324 9.04831 52.8889 9.42795C53.0455 9.80758 53.1257 10.2144 53.125 10.625V79.375C53.1261 79.9939 52.9434 80.5992 52.6001 81.1141C52.2568 81.629 51.7684 82.0304 51.1966 82.2674C50.6249 82.5044 49.9957 82.5663 49.3888 82.4453C48.7819 82.3242 48.2246 82.0257 47.7875 81.5875C42.5187 76.3125 34.5688 74.9688 26.1 75.8375C17.7 76.7125 9.475 79.6938 4.525 82.1688C4.04857 82.4075 3.519 82.5204 2.98662 82.4968C2.45424 82.4732 1.93674 82.3139 1.4833 82.0339C1.02986 81.754 0.655548 81.3627 0.39593 80.8973C0.136312 80.432 1.78821e-05 79.9079 0 79.375L0 10.625C1.94584e-05 10.0451 0.161377 9.47673 0.466022 8.98333C0.770666 8.48994 1.20658 8.09104 1.725 7.83126L3.125 10.625L1.725 7.83126L1.7375 7.82501L1.7625 7.81251L1.84375 7.77501C2.29682 7.54936 2.75529 7.33472 3.21875 7.13126C8.62806 4.70881 14.287 2.88749 20.0937 1.70001H20.0875ZM6.25 12.625V74.55C11.5375 72.4063 18.4 70.35 25.4625 69.625C32.6062 68.8875 40.3812 69.4813 46.875 73.125V11.9875C40.2313 6.18126 30.4313 6.01251 21.3125 7.83751C16.1366 8.89648 11.0875 10.5013 6.25 12.625V12.625Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M79.9125 1.70001C69.925 -0.299986 56.775 -0.562487 47.7875 8.41251C47.4975 8.70326 47.2676 9.04831 47.1111 9.42795C46.9545 9.80758 46.8743 10.2144 46.875 10.625V79.375C46.8739 79.9939 47.0566 80.5992 47.3999 81.1141C47.7432 81.629 48.2316 82.0304 48.8034 82.2674C49.3751 82.5044 50.0043 82.5663 50.6112 82.4453C51.2181 82.3242 51.7754 82.0257 52.2125 81.5875C57.4813 76.3125 65.4313 74.9688 73.9 75.8375C82.3 76.7125 90.525 79.6938 95.475 82.1688C95.9514 82.4075 96.481 82.5204 97.0134 82.4968C97.5458 82.4732 98.0633 82.3139 98.5167 82.0339C98.9701 81.754 99.3445 81.3627 99.6041 80.8973C99.8637 80.432 100 79.9079 100 79.375V10.625C100 10.0451 99.8386 9.47673 99.534 8.98333C99.2293 8.48994 98.7934 8.09104 98.275 7.83126L96.875 10.625L98.275 7.83126L98.2625 7.82501L98.2375 7.81251L98.1563 7.77501L97.8625 7.63126C96.2229 6.86568 94.5573 6.15693 92.8688 5.50626C88.6635 3.8791 84.3297 2.60593 79.9125 1.70001V1.70001ZM93.75 12.625V74.55C88.4625 72.4063 81.6 70.35 74.5375 69.625C67.3938 68.8875 59.6188 69.4813 53.125 73.125V11.9875C59.7688 6.18126 69.5688 6.01251 78.6875 7.83751C83.8635 8.89639 88.9125 10.5012 93.75 12.625V12.625Z"
      fill={color}
    />
  </svg>
)

Book.propTypes = {
  size: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
}

Book.defaultProps = {
  size: 100,
  color: 'currentColor',
}

export default Book
