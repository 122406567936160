export const EMPTY_USER = {
  name: '',
  last_name: '',
  day: '',
  month: '',
  year: '',
  email: '',
  sex: '',
  password: '',
  newPassword: '',
  confirmPassword: '',
  isLoading: true,
  avatar: null,
  file: null,
  theme: 'default',
}
