import React from 'react'
import PropTypes from 'prop-types'

const Pancil = ({ size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={(22.423 * size) / 23.135}
    height={size}
    viewBox="0 0 22.423 23.135"
  >
    <g transform="matrix(0.999, -0.035, 0.035, 0.999, -3882.438, -1929.526)">
      <path
        d="M8.9,23.332H0V8.474L4.451,0,8.9,8.474Zm0,0"
        transform="translate(3818.433 2087.194) rotate(-135)"
        fill="#fad17f"
      />
      <path
        d="M4.451,23.332H0V0L4.451,8.474Zm0,0"
        transform="translate(3815.285 2084.047) rotate(-135)"
        fill="#fad17f"
      />
      <path
        d="M8.9,0A2.226,2.226,0,0,1,6.677,2.226,2.226,2.226,0,0,1,4.451,0,2.226,2.226,0,0,1,2.226,2.226,2.226,2.226,0,0,1,0,0V14.858H8.9Zm0,0"
        transform="translate(3824.425 2081.202) rotate(-135)"
        fill="#cc2a35"
      />
      <path
        d="M0,14.858ZM0,0"
        transform="translate(3818.13 2074.907) rotate(-135)"
        fill="#f1ca3f"
      />
      <path
        d="M2.226,0,0,4.237H4.451Zm0,0"
        transform="translate(3816.859 2085.62) rotate(-135)"
        fill="#cc2a35"
      />
    </g>
  </svg>
)

Pancil.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

Pancil.defaultProps = {
  size: 23.135,
  color: 'currentColor',
}

export default Pancil
