import React from 'react'
import PropTypes from 'prop-types'

const ArrowDown = ({ size, color }) => (
  <svg
    width={size}
    height={(12 * size) / 22}
    viewBox="0 0 22 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.1573 11.1699C10.8241 11.1764 10.4992 11.0658 10.239 10.8575L1.55923 3.86872C1.26382 3.63148 1.07463 3.28647 1.03327 2.90958C0.991923 2.53268 1.1018 2.15479 1.33874 1.85902C1.57567 1.56325 1.92025 1.37384 2.29668 1.33245C2.67311 1.29107 3.05054 1.4011 3.34596 1.63834L11.1002 7.90065L18.6351 1.59995C18.7789 1.47882 18.9452 1.38733 19.1244 1.33075C19.3036 1.27417 19.4922 1.2536 19.6794 1.27024C19.8666 1.28688 20.0486 1.34039 20.2151 1.4277C20.3815 1.51501 20.5291 1.6344 20.6493 1.779C20.7825 1.92351 20.8844 2.09399 20.9487 2.27976C21.0129 2.46554 21.0382 2.6626 21.0229 2.8586C21.0076 3.0546 20.952 3.24532 20.8596 3.4188C20.7672 3.59229 20.6401 3.74481 20.4861 3.8668L12.0513 10.9115C11.7905 11.0953 11.4758 11.1863 11.1573 11.1699V11.1699Z"
      fill={color}
    />
  </svg>
)

ArrowDown.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

ArrowDown.defaultProps = {
  size: 22,
  color: 'currentColor',
}

export default ArrowDown
