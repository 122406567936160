import { makeRequest } from 'utils/request'

export const authenticate = (email, password) =>
  makeRequest({
    url: '/api/auth/login',
    method: 'post',
    headers: {
      Accept: 'application/json;charset=UTF-8',
    },
    data: {
      email,
      password,
      // password_hash: md5(`${password}${salt}`),
      // password_hash2: md5(password),
    },
  })

export const registration = (name, email, password) =>
  makeRequest({
    url: '/api/auth/register',
    method: 'post',
    headers: {
      Accept: 'application/json;charset=UTF-8',
    },
    data: {
      name,
      email,
      password: password,
    },
  })
