export const MODAL_TYPES = {
  GROUP: 'group',
  BUG_REPORT: 'bugReport',
}

export const MENU = {
  MAIN: 'main',
  MY_RECIPES: 'my_recipes',
  RECIPES: 'recipes',
}

export const MENU_FOR_DEMO = [
  { title: 'Главная', path: '/' },
  { title: 'Рецепты', path: '/recipes' },
]

export const MENU_FOR_AUTH_USER = [
  { title: 'Главная', path: '/' },
  { title: 'Рецепты', path: '/recipes' },
  { title: 'Мои рецепты', path: '/my_recipes' },
]

export const MONTHS = {
  RU: {
    LONG: [
      'Январь',
      'Февраль',
      'Март',
      'Апрель',
      'Май',
      'Июнь',
      'Июль',
      'Август',
      'Сентябрь',
      'Октябрь',
      'Ноябрь',
      'Декабрь',
    ],
    SHORT: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
  },
  EN: {
    LONG: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    SHORT: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  },
}

export const RECIPE_TYPE = [
  { id: 1, name: 'Вырезка' },
  { id: 2, name: 'Полный' },
]

export const TIME_OF_DAY = [
  { id: 1, name: 'Завтрак', value: 'breakfast' },
  { id: 2, name: 'Обед', value: 'lunch' },
  { id: 3, name: 'Ужин', value: 'dinner' },
  { id: 4, name: 'Перекус', value: 'snack' },
]

export const TIME_OF_DAY_NAME = {
  breakfast: 'Завтрак',
  lunch: 'Обед',
  dinner: 'Ужин',
  snack: 'Перекус',
}

export const UNITS = {
  piece: 'шт.',
  gram: 'грамм',
}

export const SPECIFICATIONS = [
  { id: 1, component: require('../icons/meat').default, value: 'meat', name: 'Мясное блюдо' },
  { id: 2, component: require('../icons/fish').default, value: 'fish', name: 'Рыбное блюдо' },
  { id: 2, component: require('../icons/crab').default, value: 'crab', name: 'Морепродукты' },
  { id: 3, component: require('../icons/chicken').default, value: 'chicken', name: 'Блюдо из курицы' },
  { id: 4, component: require('../icons/vegan').default, value: 'vegan', name: 'Овощное блюдо' },
  { id: 5, component: require('../icons/cake').default, value: 'cake', name: 'Десерт' },
  { id: 6, component: require('../icons/fruit').default, value: 'fruit', name: 'Блюдо из фруктов' },
  { id: 7, component: require('../icons/spicy').default, value: 'spicy', name: 'Острое блюдо' },
  { id: 8, component: require('../icons/diet').default, value: 'diet', name: 'Диетическое блюдо' },
]

export const THEMES = [
  { component: require('../icons/themes/default').default, title: 'Default theme', name: 'default' },
  { component: require('../icons/themes/white').default, title: 'White theme', name: 'white' },
  { component: require('../icons/themes/black').default, title: 'Black theme', name: 'black' },
  { component: require('../icons/themes/blue').default, title: 'Blue theme', name: 'blue' },
  { component: require('../icons/themes/indigo').default, title: 'Indigo theme', name: 'indigo' },
  { component: require('../icons/themes/purple').default, title: 'Purple theme', name: 'purple' },
  { component: require('../icons/themes/creme').default, title: 'Creme theme', name: 'creme' },
  { component: require('../icons/themes/gray').default, title: 'Dark gray theme', name: 'gray' },
]

export const THEMES_DARK = ['default', 'black', 'indigo', 'purple', 'gray']
export const THEMES_LIGHT = ['white', 'blue', 'creme']
