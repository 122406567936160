import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import Scrollbars from 'react-custom-scrollbars'
import classNames from 'classnames'
import noop from 'lodash/noop'

import Checkbox from 'components/Checkbox'
import ArrowDown from 'icons/arrowDown'

import ThemeContext from 'contexts/ThemeContext'

import styles from './styles.module.scss'

const FilterItem = ({ title, items, name, selectedItems, onChange, icon }) => {
  const theme = useContext(ThemeContext)
  const [isExpanded, setIsExpanded] = useState(false)
  const toggleList = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <div
      className={classNames(styles.filter__item, {
        [styles.list__open]: isExpanded,
      })}
    >
      <div className={classNames(styles.item__title, styles[theme])} onClick={toggleList}>
        <div className={styles.title__left}>
          {icon}
          <div className={styles.title}>
            {title} {selectedItems.length > 0 && <span>( {selectedItems.length} )</span>}
          </div>
        </div>
        <ArrowDown size={16} />
      </div>
      <div className={classNames(styles.item__list, styles[theme], { [styles.open]: isExpanded })}>
        <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={270} thumbSize={100}>
          {items &&
            items.map(item => (
              <div key={item.value} className={styles.list__item}>
                <Checkbox
                  items={[item]}
                  name={name}
                  selectedItems={selectedItems}
                  containerClassName={styles.checkboxContainer}
                  checkboxClassName={styles.checkbox}
                  checkboxNameClassName={styles.checkboxName}
                  handleChange={onChange}
                />
              </div>
            ))}
        </Scrollbars>
      </div>
    </div>
  )
}

FilterItem.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  selectedItems: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
}

FilterItem.defaultProps = {
  items: [],
  selectedItems: [],
  title: '',
  name: '',
  onChange: noop,
}

export default FilterItem
