import React from 'react'
import PropTypes from 'prop-types'

const Close = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.38447 6.98125L12.6803 2.69539C12.8684 2.50727 12.9741 2.25212 12.9741 1.98608C12.9741 1.72003 12.8684 1.46489 12.6803 1.27676C12.4922 1.08864 12.237 0.982956 11.971 0.982956C11.705 0.982956 11.4498 1.08864 11.2617 1.27676L6.97583 5.57261L2.68997 1.27676C2.50185 1.08864 2.2467 0.982956 1.98066 0.982956C1.71461 0.982956 1.45946 1.08864 1.27134 1.27676C1.08322 1.46489 0.977535 1.72003 0.977535 1.98608C0.977535 2.25212 1.08322 2.50727 1.27134 2.69539L5.56719 6.98125L1.27134 11.2671C1.1777 11.36 1.10338 11.4705 1.05266 11.5922C1.00194 11.714 0.97583 11.8445 0.97583 11.9764C0.97583 12.1083 1.00194 12.2389 1.05266 12.3606C1.10338 12.4824 1.1777 12.5929 1.27134 12.6857C1.36422 12.7794 1.47471 12.8537 1.59645 12.9044C1.71819 12.9551 1.84877 12.9813 1.98066 12.9813C2.11254 12.9813 2.24312 12.9551 2.36486 12.9044C2.4866 12.8537 2.5971 12.7794 2.68997 12.6857L6.97583 8.38989L11.2617 12.6857C11.3546 12.7794 11.4651 12.8537 11.5868 12.9044C11.7085 12.9551 11.8391 12.9813 11.971 12.9813C12.1029 12.9813 12.2335 12.9551 12.3552 12.9044C12.4769 12.8537 12.5874 12.7794 12.6803 12.6857C12.774 12.5929 12.8483 12.4824 12.899 12.3606C12.9497 12.2389 12.9758 12.1083 12.9758 11.9764C12.9758 11.8445 12.9497 11.714 12.899 11.5922C12.8483 11.4705 12.774 11.36 12.6803 11.2671L8.38447 6.98125Z"
      fill={color}
    />
  </svg>
)

Close.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

Close.defaultProps = {
  size: 13,
  color: 'currentColor',
}

export default Close
