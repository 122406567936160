export const initialValues = () => ({
  type: 'Вырезка',
  title: '',
  link: '',
  timeOfDay: [],
  specifications: [],
  description: '',
  privacy: 'private',
  country: '',
  category: '',
  group: '',
  coverUrl: '',
})
