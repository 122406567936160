import React, { useState } from 'react'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { connect } from 'react-redux'
import noop from 'lodash/noop'
import { Formik } from 'formik'
import { toastr } from 'react-redux-toastr'
import { TIME_OF_DAY } from 'utils/constants'

import Input from 'components/Input'
import Select from 'components/Select'
import Checkbox from 'components/Checkbox'
import Textarea from 'components/Textarea'
import RectangleButton from 'components/Button/RectangleButton'
import AddRecipe from 'icons/addRecipe'
import Specifications from 'components/Specifications'
import Cover from 'components/Cover'
import Hr from 'components/Hr'

import { actionCreateRecipeRequest } from 'store/recipes/actions'
import { selectCountries, selectCategories } from 'store/dictionaries/selectors'
import { selectGroupsEntities } from 'store/groups/selectors'

import { initialValues } from 'containers/NewRecipe/utils'
import { getValidationSchema } from 'containers/NewRecipe/validation'

import styles from './styles.module.scss'

const mapStateToProps = state => ({
  countries: selectCountries(state),
  categories: selectCategories(state),
  groups: selectGroupsEntities(state),
})

const mapDispatchToProps = {
  createRecipe: actionCreateRecipeRequest,
}

const NewRecipe = ({ countries, categories, history, createRecipe, groups }) => {
  const [cover, setCover] = useState(null)
  const [file, setFile] = useState(null)
  const uploadHandler = (setFieldValue, setFieldTouched) => event => {
    const file = event.target.files[0]

    if (file) {
      if (file.size > 2097152) {
        toastr.error('Размер файла не может превышать 2МБ')
        return
      }

      const fileURL = URL.createObjectURL(file)
      setCover({ name: file.name, fileURL })
      setFieldValue('coverUrl', '')
      setFieldTouched('coverUrl', true, false)
      setFile(file)
    }
  }
  const changeInputCoverHandler = (setFieldValue, setFieldTouched) => event => {
    const { value } = event.target
    setFieldValue('coverUrl', value)
    setFieldTouched('coverUrl', true, false)
    setCover(null)
  }
  const selectHandler = (setFieldValue, setFieldTouched) => (value, fieldName) => {
    setFieldValue(fieldName, value.name || value.title)
    setFieldTouched(fieldName, true, false)
  }
  const changeInputHandler = (setFieldValue, setFieldTouched) => event => {
    const { value, name } = event.target
    setFieldValue(name, value)
    setFieldTouched(name, true, false)
  }
  const checkboxHandler = (values, setFieldValue, setFieldTouched) => param => {
    const { timeOfDay } = values
    const newTimeOfDay = timeOfDay.includes(param.value)
      ? timeOfDay.filter(item => item !== param.value)
      : timeOfDay.concat([param.value])

    setFieldValue('timeOfDay', newTimeOfDay)
    setFieldTouched('timeOfDay', true, false)
  }
  const specificationsHandler = (values, setFieldValue, setFieldTouched) => value => {
    const { specifications } = values
    const newSpecifications = specifications.includes(value)
      ? specifications.filter(item => item !== value)
      : specifications.concat([value])

    setFieldValue('specifications', newSpecifications)
    setFieldTouched('specifications', true, false)
  }
  const submitHandler = values => {
    createRecipe({ fields: values, file, history })
  }
  const cancelHandler = () => {
    history.goBack()
  }

  return (
    <Formik
      initialValues={initialValues()}
      validationSchema={getValidationSchema()}
      onSubmit={submitHandler}
    >
      {({ handleSubmit, setFieldValue, setFieldTouched, submitCount, values, errors, touched }) => (
        <div className={styles.wrapper}>
          <div className={styles.container}>
            <div className={styles.header}>
              <div className={styles.header_title}>
                <div className={styles.icon}>
                  <AddRecipe size={40} />
                </div>
                <span>Добавить рецепт</span>
              </div>
              <div className={styles.header_buttons}>
                <RectangleButton
                  title="Сохранить"
                  onClick={handleSubmit}
                  externalStyle={styles.submit}
                />
                <RectangleButton
                  title="Отмена"
                  onClick={cancelHandler}
                  externalStyle={styles.cancel}
                  theme="whiteWithGreenBorder"
                />
              </div>
            </div>
            <Hr />
            <div className={styles.content}>
              <div className={styles.coverWrapper}>
                <div className={styles.cover}>
                  <Cover cover={cover} coverUrl={values.coverUrl} />
                  <div className={styles.cover__upload}>
                    <input
                      className={styles.inputfile}
                      id="avatar"
                      type="file"
                      name="avatar"
                      onChange={uploadHandler(setFieldValue, setFieldTouched)}
                      accept="image/*"
                    />
                    <label htmlFor="avatar"> Загрузить</label>
                  </div>
                </div>
                <div className={styles.sizeInfo}>Размер файла не может превышать 2МБ</div>
                <div className={styles.uploadWrapper}>
                  <Input
                    placeholder="или укажите url фото"
                    type="text"
                    name="coverUrl"
                    id="coverUrl"
                    value={values.coverUrl || ''}
                    inputClassName={styles.inputCover}
                    containerClassName={styles.inputCoverContainer}
                    handleChange={changeInputCoverHandler(setFieldValue, setFieldTouched)}
                  />
                </div>
              </div>
              <div className={styles.fields}>
                <div className={styles.row}>
                  <Input
                    label="Название*"
                    placeholder="Введите название рецепта"
                    type="text"
                    name="title"
                    id="title"
                    value={values.title}
                    inputClassName={styles.input}
                    containerClassName={styles.inputContainer}
                    handleChange={changeInputHandler(setFieldValue, setFieldTouched)}
                    errors={errors}
                    touched={touched}
                    submitCount={submitCount}
                  />
                  <Input
                    label="Ссылка на источник*"
                    placeholder="Введите ссылку на источник"
                    type="text"
                    name="link"
                    id="link"
                    value={values.link}
                    inputClassName={styles.input}
                    containerClassName={styles.inputContainer}
                    handleChange={changeInputHandler(setFieldValue, setFieldTouched)}
                    errors={errors}
                    touched={touched}
                    submitCount={submitCount}
                  />
                </div>
                <div className={styles.row}>
                  <Select
                    label="Категория*"
                    placeholder="Выберите категорию"
                    id="category"
                    items={categories}
                    selectedItems={values.category}
                    selectClassName={styles.select}
                    containerClassName={styles.selectContainer}
                    handleSelect={selectHandler(setFieldValue, setFieldTouched)}
                    maxHeight={230}
                    errors={errors}
                    touched={touched}
                    submitCount={submitCount}
                  />
                  <Select
                    label="География блюда"
                    placeholder="Выберите кухню мира"
                    id="country"
                    items={countries}
                    selectedItems={values.country}
                    selectClassName={styles.select}
                    containerClassName={styles.selectContainer}
                    handleSelect={selectHandler(setFieldValue, setFieldTouched)}
                    maxHeight={230}
                  />
                </div>
                {groups.length > 0 && (
                  <div className={classNames(styles.row, styles.singelField)}>
                    <Select
                      label="Группа"
                      placeholder="Выберите группу"
                      id="group"
                      items={groups}
                      selectedItems={values.group}
                      selectClassName={styles.select}
                      containerClassName={styles.selectContainer}
                      handleSelect={selectHandler(setFieldValue, setFieldTouched)}
                      maxHeight={230}
                    />
                  </div>
                )}
                <div className={classNames(styles.row, styles.rowBaseline)}>
                  <Checkbox
                    label="Подходит на:"
                    id="timeOfDay"
                    items={TIME_OF_DAY}
                    selectedItems={values.timeOfDay}
                    containerClassName={styles.checkboxContainer}
                    checkboxClassName={styles.checkbox}
                    handleChange={checkboxHandler(values, setFieldValue, setFieldTouched)}
                    maxHeight={230}
                  />
                </div>
                <div className={classNames(styles.row, styles.rowBaseline)}>
                  <Specifications
                    onChange={specificationsHandler(values, setFieldValue, setFieldTouched)}
                    selectedItems={values.specifications}
                    labelClassName={styles.specLabel}
                    containerClassName={styles.specContainer}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.container}>
            <div className={styles.row}>
              <Textarea
                id="description"
                label="Описание"
                name="description"
                placeholder="Введите описание рецепта"
                value={values.description}
                textareaClassName={styles.textarea}
                containerClassName={styles.inputContainer}
                onChange={changeInputHandler(setFieldValue, setFieldTouched)}
              />
            </div>
          </div>
        </div>
      )}
    </Formik>
  )
}

NewRecipe.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.shape({})),
  categories: PropTypes.arrayOf(PropTypes.shape({})),
  groups: PropTypes.arrayOf(PropTypes.shape({})),
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }),
  createRecipe: PropTypes.func,
}

NewRecipe.defaultProps = {
  countries: [],
  categories: [],
  groups: [],
  history: {},
  createRecipe: noop,
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(NewRecipe)
