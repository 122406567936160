import React from 'react'
import PropTypes from 'prop-types'

const Group = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.89474 7.89474V15.7895H17.3684V7.89474H7.89474ZM0 0H4.73684V1.57895H25.2632V0H30V4.73684H28.4211V25.2632H30V30H25.2632V28.4211H4.73684V30H0V25.2632H1.57895V4.73684H0V0ZM4.73684 25.2632V26.8421H25.2632V25.2632H26.8421V4.73684H25.2632V3.15789H4.73684V4.73684H3.15789V25.2632H4.73684ZM6.31579 6.31579H18.9474V12.6316H23.6842V23.6842H9.47368V17.3684H6.31579V6.31579ZM18.9474 17.3684H11.0526V22.1053H22.1053V14.2105H18.9474V17.3684ZM1.57895 1.57895V3.15789H3.15789V1.57895H1.57895ZM26.8421 1.57895V3.15789H28.4211V1.57895H26.8421ZM26.8421 26.8421V28.4211H28.4211V26.8421H26.8421ZM1.57895 26.8421V28.4211H3.15789V26.8421H1.57895Z"
      fill={color}
    />
  </svg>
)

Group.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

Group.defaultProps = {
  size: 30,
  color: 'currentColor',
}

export default Group
