import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { connect } from 'react-redux'
import noop from 'lodash/noop'

import { THEMES } from 'utils/constants'

import { actionSetTheme } from 'store/ui/actions'
import { actionUpdateUserThemeRequest } from 'store/user/actions'
import { selectSelectedTheme } from 'store/ui/selectors'
import { actionCloseSidebar } from 'store/ui/actions'

import styles from './styles.module.scss'

const mapStateToProps = state => ({
  selectedTheme: selectSelectedTheme(state),
})

const mapDispatchToProps = {
  setTheme: actionSetTheme,
  updateUserTheme: actionUpdateUserThemeRequest,
  closeSidebar: actionCloseSidebar,
}

const Themes = ({ setTheme, updateUserTheme, selectedTheme, closeSidebar }) => {
  const setThemeHandler = title => () => {
    setTheme(title)
    updateUserTheme(title)
  }

  useEffect(() => {
    closeSidebar()
  }, [closeSidebar])

  return (
    <div className={styles.wrapper}>
      <div className={styles.themes__title}>Темы рабочего пространства</div>
      <div className={styles.themes__list}>
        {THEMES.map(({ component: Component, title, name }) => (
          <div
            key={title}
            className={classNames(styles.list__item, {
              [styles.selected]: selectedTheme === name,
            })}
            onClick={setThemeHandler(name)}
          >
            <Component />
            <div className={styles.item__title}>{title}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

Themes.propTypes = {
  setTheme: PropTypes.func,
  updateUserTheme: PropTypes.func,
  closeSidebar: PropTypes.func,
  selectedTheme: PropTypes.string,
}

Themes.defaultProps = {
  setTheme: noop,
  updateUserTheme: noop,
  closeSidebar: noop,
  selectedTheme: '',
}

export default connect(mapStateToProps, mapDispatchToProps)(Themes)
