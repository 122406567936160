import React from 'react'
import PropTypes from 'prop-types'

const HeartFill = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.041 8.30858C26.6483 7.39932 26.0821 6.57535 25.374 5.8828C24.6654 5.18819 23.83 4.63619 22.9131 4.25683C21.9623 3.86188 20.9426 3.65973 19.9131 3.6621C18.4688 3.6621 17.0596 4.05761 15.835 4.80468C15.542 4.98339 15.2637 5.17968 15 5.39354C14.7363 5.17968 14.458 4.98339 14.165 4.80468C12.9404 4.05761 11.5312 3.6621 10.0869 3.6621C9.04687 3.6621 8.03906 3.86132 7.08691 4.25683C6.16699 4.63769 5.33789 5.18554 4.62598 5.8828C3.91698 6.57457 3.35062 7.39873 2.95898 8.30858C2.55176 9.25487 2.34375 10.2598 2.34375 11.2939C2.34375 12.2695 2.54297 13.2861 2.93848 14.3203C3.26953 15.1846 3.74414 16.081 4.35059 16.9863C5.31152 18.4189 6.63281 19.9131 8.27344 21.4277C10.9922 23.9385 13.6846 25.6728 13.7988 25.7432L14.4932 26.1885C14.8008 26.3848 15.1963 26.3848 15.5039 26.1885L16.1982 25.7432C16.3125 25.6699 19.002 23.9385 21.7236 21.4277C23.3643 19.9131 24.6855 18.4189 25.6465 16.9863C26.2529 16.081 26.7305 15.1846 27.0586 14.3203C27.4541 13.2861 27.6533 12.2695 27.6533 11.2939C27.6563 10.2598 27.4482 9.25487 27.041 8.30858V8.30858Z"
      fill={color}
    />
  </svg>
)

HeartFill.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

HeartFill.defaultProps = {
  size: 30,
  color: 'currentColor',
}

export default HeartFill
