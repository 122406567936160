import React from 'react'
import PropTypes from 'prop-types'

const Clock = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.875 0.875C7.59152 0.875 0.875 7.59152 0.875 15.875C0.875 24.1585 7.59152 30.875 15.875 30.875C24.1585 30.875 30.875 24.1585 30.875 15.875C30.875 7.59152 24.1585 0.875 15.875 0.875ZM15.875 28.3304C8.99777 28.3304 3.41964 22.7522 3.41964 15.875C3.41964 8.99777 8.99777 3.41964 15.875 3.41964C22.7522 3.41964 28.3304 8.99777 28.3304 15.875C28.3304 22.7522 22.7522 28.3304 15.875 28.3304Z"
      fill={color}
    />
    <path
      d="M21.7243 20.1138L16.9498 16.6618V8.375C16.9498 8.22768 16.8292 8.10715 16.6819 8.10715H15.0714C14.9241 8.10715 14.8036 8.22768 14.8036 8.375V17.596C14.8036 17.683 14.8437 17.7634 14.914 17.8136L20.452 21.8516C20.5725 21.9386 20.7399 21.9118 20.827 21.7946L21.7846 20.4888C21.8716 20.365 21.8449 20.1975 21.7243 20.1138V20.1138Z"
      fill={color}
    />
  </svg>
)

Clock.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

Clock.defaultProps = {
  size: 30,
  color: 'currentColor',
}

export default Clock
