import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'

import styles from './styles.module.scss'

const Checkbox = ({
  items,
  label,
  name,
  selectedItems,
  handleChange,
  checkboxClassName,
  checkboxNameClassName,
  containerClassName,
}) => {
  const changeHandler = item => () => {
    handleChange(item, name, selectedItems.includes(item.value))
  }

  return (
    <div className={classNames(containerClassName, styles.container)}>
      {label && <span className={styles.label}>{label}</span>}
      <div className={styles.group}>
        {items.map((item, index) => (
          <label key={index} className={classNames(checkboxClassName, styles.checkbox)}>
            <input
              type="checkbox"
              name="checkbox"
              checked={selectedItems.includes(item.value)}
              onChange={changeHandler(item)}
            />
            <span className={classNames(checkboxNameClassName, styles.name)}>
              <span className={styles.text}>{item.name}</span>
            </span>
          </label>
        ))}
      </div>
    </div>
  )
}

Checkbox.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  label: PropTypes.string,
  name: PropTypes.string,
  selectedItems: PropTypes.arrayOf(PropTypes.string),
  handleChange: PropTypes.func,
  checkboxClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  checkboxNameClassName: PropTypes.string,
}

Checkbox.defaultProps = {
  items: [],
  label: '',
  name: '',
  selectedItems: [],
  handleChange: noop,
  checkboxClassName: '',
  containerClassName: '',
  checkboxNameClassName: '',
}

export default Checkbox
