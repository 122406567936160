export const getBatchRequestErrors = (results = []) =>
  results.reduce((acc, r) => {
    if (r.error) acc.push(r.error)
    return acc
  }, [])

export const getBatchRequestData = (results = []) =>
  results.reduce((acc, r) => {
    if (!r.error) acc.push({ ...r.data })
    return acc
  }, [])
