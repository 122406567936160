import React from 'react'
import PropTypes from 'prop-types'

const White = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 270 152"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="270" height="152" rx="4" fill="#FAFAFA" />
    <rect x="59" y="30" width="207" height="118" rx="2" fill="white" />
    <rect x="59.5" y="30.5" width="206" height="117" rx="1.5" stroke="black" strokeOpacity="0.1" />
    <path
      d="M0 41H48C49.1046 41 50 41.8954 50 43V55C50 56.1046 49.1046 57 48 57H0V41Z"
      fill="#4488FF"
    />
    <path
      d="M0 64H48C49.1046 64 50 64.8954 50 66V78C50 79.1046 49.1046 80 48 80H0V64Z"
      fill="black"
      fillOpacity="0.1"
    />
    <circle cx="74.5" cy="15.5" r="8.5" fill="#2CA27D" />
  </svg>
)

White.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
}

White.defaultProps = {
  width: 270,
  height: 152,
}

export default White
