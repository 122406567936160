import { combineReducers } from 'redux'
import { reducer as toastr } from 'react-redux-toastr'

import ui from './store/ui/reducer'
import auth from './store/auth/reducer'
import user from './store/user/reducer'
import dictionaries from './store/dictionaries/reducer'
import recipes from './store/recipes/reducer'
import filters from './store/filters/reducer'
import groups from './store/groups/reducer'

export default combineReducers({
  toastr,
  ui,
  auth,
  user,
  dictionaries,
  recipes,
  filters,
  groups,
})
