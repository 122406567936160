import React from 'react'
import PropTypes from 'prop-types'

const ArrowDown = ({ size, color }) => (
  <svg
    width={(10 * size) / 11}
    height={size}
    viewBox="0 0 10 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.319708 5.36875C0.383038 5.30526 0.458271 5.25489 0.541099 5.22052C0.623927 5.18615 0.712721 5.16846 0.802396 5.16846C0.892072 5.16846 0.980866 5.18615 1.06369 5.22052C1.14652 5.25489 1.22175 5.30526 1.28508 5.36875L4.89297 8.978L8.50086 5.36875C8.62888 5.24073 8.80251 5.16881 8.98355 5.16881C9.16459 5.16881 9.33822 5.24073 9.46624 5.36875C9.59425 5.49677 9.66617 5.6704 9.66617 5.85144C9.66617 6.03248 9.59425 6.20611 9.46624 6.33413L5.37566 10.4247C5.31233 10.4882 5.2371 10.5386 5.15427 10.5729C5.07144 10.6073 4.98265 10.625 4.89297 10.625C4.8033 10.625 4.7145 10.6073 4.63168 10.5729C4.54885 10.5386 4.47361 10.4882 4.41028 10.4247L0.319708 6.33413C0.256218 6.2708 0.205845 6.19556 0.171476 6.11274C0.137106 6.02991 0.119415 5.94111 0.119415 5.85144C0.119415 5.76176 0.137106 5.67297 0.171476 5.59014C0.205845 5.50731 0.256218 5.43208 0.319708 5.36875V5.36875Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.89297 0.625C5.07379 0.625 5.2472 0.672411 5.37505 0.756802C5.50291 0.841193 5.57474 0.955653 5.57474 1.075V9.175C5.57474 9.29435 5.50291 9.40881 5.37505 9.4932C5.2472 9.57759 5.07379 9.625 4.89297 9.625C4.71216 9.625 4.53875 9.57759 4.4109 9.4932C4.28304 9.40881 4.21121 9.29435 4.21121 9.175V1.075C4.21121 0.955653 4.28304 0.841193 4.4109 0.756802C4.53875 0.672411 4.71216 0.625 4.89297 0.625V0.625Z"
      fill={color}
    />
  </svg>
)

ArrowDown.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

ArrowDown.defaultProps = {
  size: 11,
  color: 'currentColor',
}

export default ArrowDown
