import { MONTHS } from 'utils/constants'
import sortBy from 'lodash/sortBy'
import last from 'lodash/last'

export const getArrayYears = (from, to) => {
  const currentYear = to || new Date().getFullYear()
  const years = []
  let startYear = from || 1950

  while (startYear <= currentYear) {
    const year = startYear++
    years.push({ id: year, name: year })
  }

  return years.reverse()
}

export const getArrayMonth = (lang = 'ru', short = false) => {
  const monthsLocal = MONTHS[lang.toUpperCase()] || MONTHS.RU
  const months = short ? monthsLocal.SHORT : monthsLocal.LONG

  return months.map((month, index) => ({ id: index + 1, name: month }))
}

export const getArrayDay = () => {
  return Array.from({ length: 31 }, (value, key) => ({ id: key + 1, name: key + 1 }))
}

export const parseDate = (dateString = '') => {
  const date = new Date(dateString)
  return date.toLocaleDateString()
}

export const getCoverSrcRecipe = recipe => {
  if (recipe.cover && recipe.cover.fileURL) {
    return recipe.cover.fileURL.includes('blob') ? recipe.cover.fileURL : `/${recipe.cover.fileURL}`
  }

  return recipe.coverUrl || require('../images/kisspng-breakfast 1.png')
}

export const getSortIndex = groups => {
  const sortedGroups = sortBy(groups, ['sort_index'])
  return !sortedGroups.length ? 0 : last(sortedGroups).sort_index
}

export const parseWrapToBr = (text = '') => text.replace(/(<br ?\/?>|\r\n|\r|\n)/gim, '<br />')
