import { createAction } from 'redux-actions'

export const actionCreateRecipeRequest = createAction('CREATE_RECIPE_REQUEST')
export const actionCreateRecipeSuccess = createAction('CREATE_RECIPE_SUCCESS')

export const actionLoadMyRecipesRequest = createAction('LOAD_MY_RECIPES_REQUEST')
export const actionLoadMyRecipesSuccess = createAction('LOAD_MY_RECIPES_SUCCESS')

export const actionLoadRecipeRequest = createAction('LOAD_RECIPE_REQUEST')
export const actionLoadRecipeSuccess = createAction('LOAD_RECIPE_SUCCESS')

export const actionUpdateFavoriteRecipeRequest = createAction('UPDATE_FAVORITE_RECIPE_REQUEST')

export const actionDeleteRecipeRequest = createAction('DELETE_RECIPE_REQUEST')

export const actionUpdateRecipeRequest = createAction('UPDATE_RECIPE_REQUEST')
export const actionUpdateRecipeSuccess = createAction('UPDATE_RECIPE_SUCCESS')
