import { takeEvery, put, call } from 'redux-saga/effects'
import { toastr } from 'react-redux-toastr'
import { sendBugReport } from 'api/report'
import { actionShowLoading, actionHideLoading } from 'store/ui/actions'

import { actionBugReportRequest } from './actions'

function* bugReportSaga({ payload: { topic, message } }) {
  yield put(actionShowLoading())
  const { error } = yield call(sendBugReport, { topic, message })

  if (error) {
    const errorString = error.errors.reduce((acc, e) => {
      return !acc ? e.msg : `${acc}. ${e.msg}`
    }, '')
    toastr.error(errorString || 'Не удалось отправить сообщение')
    yield put(actionHideLoading())
    return
  }

  toastr.success('Сообщение успешно отправлено')

  yield put(actionHideLoading())
}

function* watchBugReport() {
  yield takeEvery(actionBugReportRequest, bugReportSaga)
}

export default [watchBugReport()]
