import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classNames from 'classnames'
import noop from 'lodash/noop'
import ClickOutside from 'react-outside-click-handler'

import Input from 'components/Input'
import SearchIcon from 'icons/search'

import { actionChangeSearchString } from 'store/filters/actions'

import ThemeContext from 'contexts/ThemeContext'

import styles from './styles.module.scss'

const mapDispatchToProps = {
  chageSearchString: actionChangeSearchString,
}

const Search = ({ chageSearchString }) => {
  const theme = useContext(ThemeContext)
  const [value, setValue] = useState('')
  const [isExpanded, setIsExpanded] = useState(false)
  const changeHandler = e => {
    setValue(e.target.value)
  }
  const keyPressHandler = e => {
    if (e.key === 'Enter') {
      chageSearchString(value)
    }
  }
  const toggleHandler = () => {
    if (isExpanded) {
      setValue('')
      chageSearchString('')
    }
    setIsExpanded(!isExpanded)
  }
  const blurHandler = () => {
    chageSearchString(value)
  }
  const renderInput = () => {
    return (
      <Input
        placeholder="Искать"
        value={value}
        inputClassName={classNames(styles.input, styles[theme])}
        containerClassName={styles.container}
        icon={<SearchIcon size={20} />}
        handleKeyPress={keyPressHandler}
        handleChange={changeHandler}
        handleBlur={blurHandler}
      />
    )
  }

  return (
    <div className={styles.searchWrapper}>
      <ClickOutside onOutsideClick={isExpanded ? toggleHandler : noop}>
        <div className={styles.smallSearch}>
          <div onClick={toggleHandler}>
            <SearchIcon size={20} />
          </div>
          {isExpanded && <div className={styles.inputList}>{renderInput()}</div>}
        </div>
      </ClickOutside>
      <div className={styles.bigSearch}>{renderInput()}</div>
    </div>
  )
}

Search.propTypes = {
  chageSearchString: PropTypes.func,
}

Search.propTypes = {
  chageSearchString: noop,
}

export default connect(null, mapDispatchToProps)(Search)
