import React from 'react'
import PropTypes from 'prop-types'

const Heart = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.041 8.3086C26.6483 7.39933 26.0821 6.57536 25.374 5.88282C24.6654 5.18821 23.83 4.63621 22.9131 4.25684C21.9623 3.8619 20.9426 3.65975 19.9131 3.66211C18.4688 3.66211 17.0596 4.05762 15.835 4.80469C15.542 4.9834 15.2637 5.17969 15 5.39356C14.7363 5.17969 14.458 4.9834 14.165 4.80469C12.9404 4.05762 11.5312 3.66211 10.0869 3.66211C9.04687 3.66211 8.03906 3.86133 7.08691 4.25684C6.16699 4.6377 5.33789 5.18555 4.62598 5.88282C3.91698 6.57458 3.35062 7.39875 2.95898 8.3086C2.55176 9.25489 2.34375 10.2598 2.34375 11.294C2.34375 12.2695 2.54297 13.2861 2.93848 14.3203C3.26953 15.1846 3.74414 16.0811 4.35059 16.9863C5.31152 18.419 6.63281 19.9131 8.27344 21.4277C10.9922 23.9385 13.6846 25.6729 13.7988 25.7432L14.4932 26.1885C14.8008 26.3848 15.1963 26.3848 15.5039 26.1885L16.1982 25.7432C16.3125 25.6699 19.002 23.9385 21.7236 21.4277C23.3643 19.9131 24.6855 18.419 25.6465 16.9863C26.2529 16.0811 26.7305 15.1846 27.0586 14.3203C27.4541 13.2861 27.6533 12.2695 27.6533 11.294C27.6563 10.2598 27.4482 9.25489 27.041 8.3086V8.3086ZM15 23.8711C15 23.8711 4.57031 17.1885 4.57031 11.294C4.57031 8.3086 7.04004 5.88868 10.0869 5.88868C12.2285 5.88868 14.0859 7.08399 15 8.83008C15.9141 7.08399 17.7715 5.88868 19.9131 5.88868C22.96 5.88868 25.4297 8.3086 25.4297 11.294C25.4297 17.1885 15 23.8711 15 23.8711Z"
      fill={color}
    />
  </svg>
)

Heart.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

Heart.defaultProps = {
  size: 30,
  color: 'currentColor',
}

export default Heart
