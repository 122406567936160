import { createAction } from 'redux-actions'

export const actionLoadDataRequest = createAction('LOAD_DATA_REQUEST')
export const actionLoadDataSuccess = createAction('LOAD_DATA_SUCCESS')

export const actionLoadCountriesRequest = createAction('LOAD_COUNTRIES_REQUEST')
export const actionLoadCountriesSuccess = createAction('LOAD_COUNTRIES_SUCCESS')

export const actionLoadCategoriesRequest = createAction('LOAD_CATEGORIES_REQUEST')
export const actionLoadCategoriesSuccess = createAction('LOAD_CATEGORIES_SUCCESS')
