import React from 'react'
import PropTypes from 'prop-types'

const Category = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0V7.5H7.5V0H0ZM11.7188 0V7.5H30V0H11.7188ZM0 11.25V18.75H7.5V11.25H0ZM11.7188 11.25V18.75H30V11.25H11.7188ZM0 22.5V30H7.5V22.5H0ZM11.7188 22.5V30H30V22.5H11.7188Z"
      fill={color}
    />
  </svg>
)

Category.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

Category.defaultProps = {
  size: 30,
  color: 'currentColor',
}

export default Category
