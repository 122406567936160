import React from 'react'
import { Link } from 'react-router-dom'
import Scrollbars from 'react-custom-scrollbars'

import Logo from 'icons/logo'
import LogoText from 'icons/logoText'

import styles from './styles.module.scss'

const Landing = () => {
  return (
    <Scrollbars>
      <div className={styles.landingWrapper}>
        <header className={styles.header}>
          <div className={styles.header__logo}>
            <Logo size={40} />
            <LogoText />
          </div>
          <div className={styles.header__buttons}>
            <div className={styles.login}>
              <Link to="/login">Войти</Link>
            </div>
            <div className={styles.register}>
              <Link to="/registration">Регистрация</Link>
            </div>
          </div>
        </header>
        <main>
          <section className={styles.banner}>
            <div className={styles.banner__container}>
              <div className={styles.banner__title}>Ваши рецепты в одном месте</div>
              <div className={styles.banner__subtitle}>
                Сервис для хранения рецептов с web-ресурсов
              </div>
              <div className={styles.start}>
                <Link to="/registration">Начать бесплатно</Link>
              </div>
            </div>
          </section>
          <section className={styles.kitchen}>
            <div className={styles.kitchen__container}>
              <div className={styles.kitchen__img}>
                <img src={require('../../images/kitchen.png')} alt="" />
              </div>
              <div className={styles.kitchen__content}>
                <span className={styles.title}>Кулинарные книги. Как это было</span>
                <p>
                  Лет так 30-40 назад у каждой уважающей себя хозяйки была книга с рецептами. Часто
                  это были специальные записные книжки или тетрадки, в которые кроме рукописных
                  рецептов вклеивались вырезки из газет и журналов, а заодно и украшались
                  картинками/рисунками, чтобы не скучно было читать. Все это хранилось на полках или в
                  шкафчиках над плитой.
                </p>
              </div>
            </div>
          </section>
          <section className={styles.search}>
            <div className={styles.search__container}>
              <div className={styles.search__img}>
                <img src={require('../../images/search.png')} alt="" />
              </div>
              <div className={styles.search__content}>
                <span className={styles.title}>Современный подход</span>
                <p>
                  В современном мире стало проще искать рецепты. «Сырники» вбитые в качестве запроса в
                  любой поисковик, моментально выдадут вам по 10 тысяч рецептов, хватай первые десять
                  и выбирай самые грамотно оформленные. Но у большого выбора есть свои минусы &ndash;
                  попробуй вспомнить, где был тот рецепт, по которому получилось «то самое блюдо». На
                  помощь приходит <span>MY COOKING BOOK</span> - собирай свои любимые рецепты в одном
                  месте. Вы ищите лучшие рецепты, мы бережно храним их для Вас!
                </p>
              </div>
            </div>
          </section>
        </main>
        <footer>
          <span>&copy;</span> Сервис для хранения рецептов MY COOKING BOOK
        </footer>
      </div>
    </Scrollbars>
  )
}

export default Landing
