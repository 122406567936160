import React, { useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { toastr } from 'react-redux-toastr'
import noop from 'lodash/noop'

import Input from 'components/Input'
import RectangleButton from 'components/Button/RectangleButton'
import GroupIcon from 'icons/group'

import { actionCreateGroupRequest } from 'store/groups/actions'
import { actionModalClose } from 'store/ui/actions'

import styles from './styles.module.scss'

const mapDispatchToProps = {
  addGroup: actionCreateGroupRequest,
  closeModal: actionModalClose,
}

const GroupModal = ({ addGroup, closeModal }) => {
  const [groupName, setGroupName] = useState('')
  const changeInputHandler = e => setGroupName(e.target.value)
  const addGroupHandler = () => {
    if (!groupName.trim()) {
      toastr.error('Название не может быть пустым')
    }

    addGroup({ title: groupName })
    closeModal()
  }

  return (
    <div className={styles.content}>
      <div className={styles.title}>
        <GroupIcon width={26} height={26} color="#666666" />
        Добавить группу
      </div>
      <div className={styles.body}>
        <Input
          placeholder="Название группы"
          type="text"
          name="title"
          value={groupName}
          inputClassName={styles.input}
          containerClassName={styles.inputContainer}
          handleChange={changeInputHandler}
        />
        <RectangleButton
          title="Добавить"
          externalStyle={styles.button}
          theme="blue"
          disabled={!groupName}
          onClick={addGroupHandler}
        />
      </div>
    </div>
  )
}

GroupModal.propTypes = {
  addGroup: PropTypes.func,
  closeModal: PropTypes.func,
}

GroupModal.defaultProps = {
  addGroup: noop,
  closeModal: noop,
}

export default connect(null, mapDispatchToProps)(GroupModal)
