import { createAction } from 'redux-actions'

export const actionLoadGroupsRequest = createAction('LOAD_GROUPS_REQUEST')
export const actionLoadGroupsSuccess = createAction('LOAD_GROUPS_SUCCESS')

export const actionCreateGroupRequest = createAction('CREATE_GROUP_REQUEST')

export const actionDeleteGroupRequest = createAction('DELETE_GROUP_REQUEST')

export const actionUpdateGroupRequest = createAction('UPDATE_GROUP_REQUEST')
export const actionUpdateGroupSuccess = createAction('UPDATE_GROUP_SUCCESS')
