import { handleActions } from 'redux-actions'
import {
  actionChangeSearchString,
  actionChangeFilterParams,
  actionResetFilterParams,
} from './actions'

const initialParams = {
  categories: [],
  countries: [],
  specifications: [],
  timeOfDay: [],
  group: '',
}

const initialState = {
  searchString: '',
  params: initialParams,
}

const filterParams = (state, key, value, isSelected) => {
  if (typeof state.params[key] === 'object') {
    return {
      [key]: !isSelected
        ? [...state.params[key], value]
        : state.params[key].filter(item => item !== value),
    }
  }
  return {
    [key]: !isSelected ? value : '',
  }
}

export default handleActions(
  {
    [actionChangeSearchString]: (state, { payload }) => ({
      ...state,
      searchString: payload,
    }),
    [actionResetFilterParams]: state => ({
      ...state,
      params: initialParams,
    }),
    [actionChangeFilterParams]: (state, { payload: { key, value, isSelected } }) => {
      return {
        ...state,
        params: {
          ...state.params,
          ...filterParams(state, key, value, isSelected),
        },
      }
    },
  },
  initialState
)
