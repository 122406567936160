import React from 'react'
import PropTypes from 'prop-types'

const ArrowUp = ({ size, color }) => (
  <svg
    width={(10 * size) / 11}
    height={size}
    viewBox="0 0 10 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path 
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.89424 1.93614C5.07506 1.93614 5.24847 1.98188 5.37632 2.06331C5.50418 2.14473 5.576 2.25516 5.576 2.37031V10.1853C5.576 10.3004 5.50418 10.4108 5.37632 10.4923C5.24847 10.5737 5.07506 10.6194 4.89424 10.6194C4.71343 10.6194 4.54002 10.5737 4.41216 10.4923C4.28431 10.4108 4.21248 10.3004 4.21248 10.1853V2.37031C4.21248 2.25516 4.28431 2.14473 4.41216 2.06331C4.54002 1.98188 4.71343 1.93614 4.89424 1.93614V1.93614Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.41155 0.81266C4.47488 0.751404 4.55011 0.702805 4.63294 0.669644C4.71576 0.636484 4.80456 0.619415 4.89423 0.619415C4.98391 0.619415 5.0727 0.636484 5.15553 0.669644C5.23836 0.702805 5.31359 0.751404 5.37692 0.81266L9.4675 4.75929C9.59552 4.8828 9.66743 5.05032 9.66743 5.22499C9.66743 5.39966 9.59552 5.56718 9.4675 5.69069C9.33948 5.8142 9.16585 5.88359 8.98481 5.88359C8.80377 5.88359 8.63014 5.8142 8.50212 5.69069L4.89423 2.20845L1.28635 5.69069C1.15833 5.8142 0.9847 5.88359 0.803657 5.88359C0.622614 5.88359 0.448986 5.8142 0.320969 5.69069C0.192952 5.56718 0.121033 5.39966 0.121033 5.22499C0.121033 5.05032 0.192952 4.8828 0.320969 4.75929L4.41155 0.81266Z"
      fill={color}
    />
  </svg>
)

ArrowUp.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

ArrowUp.defaultProps = {
  size: 11,
  color: 'currentColor',
}

export default ArrowUp
