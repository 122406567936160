import React, { useCallback, useState, useEffect, createRef } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import classNames from 'classnames'

import Input from 'components/Input'
import Close from 'icons/close'
import PancilSimple from 'icons/pancilSimple'
import ArrowUp from 'icons/arrow-up'
import ArrowDown from 'icons/arrow-down'

import styles from './styles.module.scss'

export const GroupSettingItem = ({
  group,
  onEdit,
  onDelete,
  onUpdate,
  onSortUpdate,
  isEdit,
  isFirst,
  isLast,
}) => {
  const [name, setName] = useState(group.title)
  const refElement = createRef()

  const changeInputHandler = useCallback(e => {
    setName(e.target.value)
  }, [])

  const editHandler = useCallback(() => {
    onEdit(group._id)
  }, [group, onEdit])

  const deleteHandler = useCallback(() => {
    onDelete(group._id)
  }, [group, onDelete])

  const blurHandler = useCallback(() => {
    if (name && group.title !== name) {
      onUpdate({ id: group._id, title: name })
    }

    onEdit(null)
  }, [onEdit, onUpdate, name, group._id, group.title])

  const prevHandler = useCallback(() => {
    onSortUpdate(group._id, 'prev')
  }, [onSortUpdate, group])

  const nextHandler = useCallback(() => {
    onSortUpdate(group._id, 'next')
  }, [onSortUpdate, group])

  useEffect(() => {
    if (isEdit && refElement.current) {
      refElement.current.focus()
    }
  }, [isEdit, refElement])

  return (
    <li key={group._id} className={styles.listItem}>
      <div className={styles.arrowContainer}>
        <div
          className={classNames(styles.arrow, {
            [styles.disabled]: isFirst,
          })}
          onClick={isFirst ? noop : prevHandler}
        >
          <ArrowUp size={15} />
        </div>
        <div
          className={classNames(styles.arrow, {
            [styles.disabled]: isLast,
          })}
          onClick={isLast ? noop : nextHandler}
        >
          <ArrowDown size={15} />
        </div>
      </div>
      {isEdit && (
        <Input
          refElement={refElement}
          type="text"
          value={name}
          inputClassName={styles.input}
          containerClassName={styles.inputContainerName}
          handleChange={changeInputHandler}
          handleBlur={blurHandler}
        />
      )}
      {!isEdit && <span className={styles.title}>{group.title}</span>}
      <div className={styles.buttonGroup}>
        <div className={styles.icon} onClick={editHandler}>
          <PancilSimple />
        </div>
        <div className={styles.icon} onClick={deleteHandler}>
          <Close />
        </div>
      </div>
    </li>
  )
}

GroupSettingItem.propTypes = {
  group: PropTypes.shape({
    _id: PropTypes.string,
    title: PropTypes.string,
  }),
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
  onSortUpdate: PropTypes.func,
  isEdit: PropTypes.bool,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

GroupSettingItem.defaultProp = {
  group: {
    _id: '',
    title: '',
  },
  onEdit: noop,
  onDelete: noop,
  onUpdate: noop,
  onSortUpdate: noop,
  isEdit: false,
  isFirst: false,
  isLast: false,
}

export default GroupSettingItem
