import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import noop from 'lodash/noop'
import ClickOutside from 'react-outside-click-handler'
import Scrollbars from 'react-custom-scrollbars'

import ArrowDown from 'icons/arrowDown'
import ArrowUp from 'icons/arrowUp'

import styles from './styles.module.scss'

const Select = ({
  label,
  items,
  placeholder,
  id,
  selectedItems,
  maxHeight,
  handleSelect,
  selectClassName,
  containerClassName,
  errors,
  touched,
  submitCount,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const toggleArrow = () => setIsExpanded(!isExpanded)
  const selectHandler = item => () => {
    handleSelect(item, id)
    toggleArrow()
  }
  const renderLabel = () => {
    if (label.includes('*')) {
      return (
        <label htmlFor={id}>
          {label.replace('*', '')}
          <span>*</span>
        </label>
      )
    }
    return <label htmlFor={id}>{label}</label>
  }

  return (
    <div className={classNames(containerClassName, styles.container)}>
      <ClickOutside onOutsideClick={isExpanded ? toggleArrow : noop}>
        {label && renderLabel()}
        <div className={classNames(selectClassName, styles.select)} onClick={toggleArrow}>
          {selectedItems || placeholder}
          <div className={styles.arrow}>
            {isExpanded ? <ArrowUp size={14} /> : <ArrowDown size={14} />}
          </div>
        </div>
        {isExpanded && (
          <ul className={styles.list} style={{ maxHeight }}>
            <Scrollbars autoHeight>
              {items.map((item, index) => (
                <li key={`${item.name || item.title}_${index}`} onClick={selectHandler(item)}>
                  {item.name || item.title}
                </li>
              ))}
            </Scrollbars>
          </ul>
        )}
      </ClickOutside>
      {(touched[id] || submitCount > 0) && !!errors[id] && (
        <div className={styles.errorMessage}>{errors[id]}</div>
      )}
    </div>
  )
}

Select.propTypes = {
  label: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  placeholder: PropTypes.string,
  id: PropTypes.string,
  selectedItems: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxHeight: PropTypes.number,
  handleSelect: PropTypes.func,
  selectClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  submitCount: PropTypes.number,
  errors: PropTypes.shape({}),
  touched: PropTypes.shape({}),
}

Select.defaultProps = {
  label: '',
  items: [],
  placeholder: '',
  id: '',
  selectedItems: null,
  maxHeight: 180,
  handleSelect: noop,
  selectClassName: '',
  containerClassName: '',
  submitCount: null,
  errors: {},
  touched: {},
}

export default Select
