import * as yup from 'yup'

export const getValidationSchema = () =>
  yup.object({
    name: yup
      .string()
      .trim()
      .min(3, 'Поле "Имя" должно содержать более 3 символов')
      .required('Поле "Имя" обязательно к заполнению'),
    email: yup
      .string()
      .trim()
      .email('Неверный формат Email')
      .required('Поле "Email" обязательно к заполнению'),
    password: yup
      .string()
      .trim()
      .min(6, 'Поле "Пароль" должно содержать более 5 символов')
      .required('Поле "Пароль" обязательно к заполнению'),
    passwordConfirm: yup
      .string()
      .trim()
      .oneOf([yup.ref('password'), null], 'Пароли должны совпадать')
      .required('Поле "Пароль" обязательно к заполнению'),
    agreement: yup.boolean().oneOf([true], 'Согласие на обработку персональных даных не получено'),
  })
