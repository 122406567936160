import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import noop from 'lodash/noop'

import Book from 'icons/book'
import CardsList from 'components/CardsList'

import { selectAllRecipes, selectStatusLoading } from 'store/recipes/selectors'
import { selectFilterParams } from 'store/filters/selectors'
import {
  actionLoadMyRecipesRequest,
  actionUpdateFavoriteRecipeRequest,
} from 'store/recipes/actions'

import styles from './styles.module.scss'

const mapDispatchToProps = {
  loadRecipes: actionLoadMyRecipesRequest,
  updateFavoriteRecipe: actionUpdateFavoriteRecipeRequest,
}

const mapStateToProps = state => ({
  recipes: selectAllRecipes(state),
  filterParams: selectFilterParams(state),
  statusLoading: selectStatusLoading(state),
})

const Favorites = ({ recipes, loadRecipes, updateFavoriteRecipe, filterParams, statusLoading }) => {
  const [stateRecipes, setStateRecipes] = useState([])
  const favouriteHandler = recipe => {
    updateFavoriteRecipe({ id: recipe._id, isFavorites: !recipe.isFavorites })
  }

  useEffect(() => {
    if (!recipes.length && statusLoading !== 'success') {
      loadRecipes()
    }
  }, [recipes, loadRecipes, statusLoading])

  useEffect(() => {
    if (recipes.length) {
      setStateRecipes(recipes.filter(recipe => recipe.isFavorites))
    }
  }, [recipes])

  return (
    <div className={styles.wrapper}>
      {/* {filterParams.group && <div className={styles.titleGroup}>{filterParams.group}</div>} */}
      {stateRecipes.length > 0 && (
        <CardsList list={stateRecipes} onFavoriteClick={favouriteHandler} />
      )}
      {!stateRecipes.length && (
        <div className={styles.emptyContent}>
          <Book size={150} />
          <p>У вас нет ни одного избранного рецепта</p>
        </div>
      )}
    </div>
  )
}

Favorites.propTypes = {
  loadRecipes: PropTypes.func,
  updateFavoriteRecipe: PropTypes.func,
  recipes: PropTypes.arrayOf(PropTypes.shape({})),
  filterParams: PropTypes.shape({
    group: PropTypes.string,
  }),
  searchString: PropTypes.string,
  statusLoading: PropTypes.string,
}

Favorites.defaultProps = {
  loadRecipes: noop,
  updateFavoriteRecipe: noop,
  recipes: [],
  filterParams: {},
  searchString: '',
  statusLoading: '',
}

export default connect(mapStateToProps, mapDispatchToProps)(Favorites)
