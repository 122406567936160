import { handleActions } from 'redux-actions'
import produce from 'immer'
import {
  actionLoadMyRecipesSuccess,
  actionLoadRecipeSuccess,
  actionDeleteRecipeRequest,
} from './actions'

const initialState = {
  recipes: [],
  recipe: null,
  statusLoading: '',
}

export default handleActions(
  {
    [actionLoadMyRecipesSuccess]: (state, { payload }) => ({
      ...state,
      recipes: payload,
      statusLoading: 'success',
    }),
    [actionLoadRecipeSuccess]: (state, { payload }) => ({
      ...state,
      recipe: payload,
    }),
    [actionDeleteRecipeRequest]: (state, { payload: { recipeId } }) =>
      produce(state, newState => {
        newState.recipes = newState.recipes.filter(recipe => recipe._id !== recipeId)
      }),
  },
  initialState
)
