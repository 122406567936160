import React from 'react'
import PropTypes from 'prop-types'

const Menu = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M41.7708 22.9167H8.22917C7.1361 22.9167 6.25 23.8028 6.25 24.8958V25.1042C6.25 26.1972 7.1361 27.0833 8.22917 27.0833H41.7708C42.8639 27.0833 43.75 26.1972 43.75 25.1042V24.8958C43.75 23.8028 42.8639 22.9167 41.7708 22.9167Z"
      fill={color}
    />
    <path
      d="M41.7708 33.3333H8.22917C7.1361 33.3333 6.25 34.2194 6.25 35.3125V35.5208C6.25 36.6139 7.1361 37.5 8.22917 37.5H41.7708C42.8639 37.5 43.75 36.6139 43.75 35.5208V35.3125C43.75 34.2194 42.8639 33.3333 41.7708 33.3333Z"
      fill={color}
    />
    <path
      d="M41.7708 12.5H8.22917C7.1361 12.5 6.25 13.3861 6.25 14.4792V14.6875C6.25 15.7806 7.1361 16.6667 8.22917 16.6667H41.7708C42.8639 16.6667 43.75 15.7806 43.75 14.6875V14.4792C43.75 13.3861 42.8639 12.5 41.7708 12.5Z"
      fill={color}
    />
  </svg>
)

Menu.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

Menu.defaultProps = {
  size: 50,
  color: 'currentColor',
}

export default Menu
