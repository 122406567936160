import React, { useEffect } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { LastLocationProvider } from 'react-router-last-location'
import Scrollbars from 'react-custom-scrollbars'
import { useRoutes } from 'routes'
import noop from 'lodash/noop'

import Header from 'containers/Header'
import Modal from 'containers/Modal'
import Loader from 'components/Loader'

import { selectIsLoading, selectSelectedTheme } from 'store/ui/selectors'
import { selectIsAuthorized } from 'store/auth/selectors'
import { actionLoadUserRequest } from 'store/user/actions'
import { actionLoadDataRequest } from 'store/dictionaries/actions'

import ThemeContext from 'contexts/ThemeContext'

import styles from './styles.module.scss'

const mapStateToProps = state => ({
  isLoading: selectIsLoading(state),
  isAuthorized: selectIsAuthorized(state),
  selectedTheme: selectSelectedTheme(state),
})

const mapDispatchToProps = {
  loadUser: actionLoadUserRequest,
  loadData: actionLoadDataRequest,
}

function App({ isLoading, isAuthorized, loadUser, loadData, selectedTheme }) {
  const routes = useRoutes(isAuthorized)

  useEffect(() => {
    if (isAuthorized) {
      loadUser()
      loadData()
    }
  }, [isAuthorized, loadUser, loadData])

  return (
    <BrowserRouter>
      <LastLocationProvider>
        {isAuthorized && (
          <ThemeContext.Provider value={selectedTheme}>
            <Scrollbars>
              <div className={classNames(styles.layout, styles[selectedTheme])}>
                <Header />
                <div className={styles.centerContainer}>{routes}</div>
                <Modal />
                {isLoading && <Loader />}
              </div>
            </Scrollbars>
          </ThemeContext.Provider>
        )}
        {!isAuthorized && <div className={styles.emptyLayout}>{routes}</div>}
      </LastLocationProvider>
    </BrowserRouter>
  )
}

App.propTypes = {
  isLoading: PropTypes.bool,
  isAuthorized: PropTypes.bool,
  loadUser: PropTypes.func,
  loadData: PropTypes.func,
  selectedTheme: PropTypes.string,
}

App.defaultProps = {
  isLoading: false,
  isAuthorized: false,
  loadUser: noop,
  loadData: noop,
  selectedTheme: '',
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
