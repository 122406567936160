import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import noop from 'lodash/noop'
import { Formik } from 'formik'
import ReCAPTCHA from 'react-google-recaptcha'

import LogoText from 'icons/logoText'
import Email from 'icons/email'
import Password from 'icons/password'
import User from 'icons/user'
import Input from 'components/Input'
import RectangleButton from 'components/Button/RectangleButton'

import { actionRegistrationRequest } from 'store/auth/actions'

import { getValidationSchema } from './validation'
import { initialValues } from './utils'
import styles from './styles.module.scss'

const mapDispatchToProps = {
  onRegister: actionRegistrationRequest,
}

const Rregistration = ({ onRegister, history }) => {
  const [captcha, setCaptcha] = useState(null)
  const changeHandler = (setFieldValue, setFieldTouched) => event => {
    const { value, name } = event.target
    setFieldValue(name, value)
    setFieldTouched(name, true, false)
  }
  const registerHandler = values => {
    if (captcha) {
      onRegister({ values, history })
    }
  }
  const handleChangeCaptha = value => {
    setCaptcha(value)
  }
  const checkboxHandler = (setFieldValue, setFieldTouched) => event => {
    const { checked } = event.target
    setFieldValue('agreement', checked)
    setFieldTouched('agreement', true, false)
  }

  return (
    <div className={styles.login}>
      <div className={styles.login__container}>
        <Formik
          initialValues={initialValues()}
          validationSchema={getValidationSchema()}
          onSubmit={registerHandler}
        >
          {({
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            submitCount,
            values,
            errors,
            touched,
          }) => (
            <div className={styles.login__form}>
              <div className={styles.form__logo}>
                <Link to="/">
                  <LogoText size={200} />
                </Link>
              </div>
              <div className={styles.form__inputs}>
                <div className={styles.inputWrapper}>
                  <Input
                    placeholder="Введите имя"
                    type="text"
                    name="name"
                    id="name"
                    value={values.name}
                    inputClassName={styles.input}
                    containerClassName={styles.inputs__item}
                    icon={<User />}
                    handleChange={changeHandler(setFieldValue, setFieldTouched)}
                    errors={errors}
                    touched={touched}
                    submitCount={submitCount}
                  />
                </div>
                <div className={styles.inputWrapper}>
                  <Input
                    placeholder="Введите Email"
                    type="text"
                    name="email"
                    id="email"
                    value={values.email}
                    inputClassName={styles.input}
                    containerClassName={styles.inputs__item}
                    icon={<Email />}
                    handleChange={changeHandler(setFieldValue, setFieldTouched)}
                    errors={errors}
                    touched={touched}
                    submitCount={submitCount}
                  />
                </div>
                <div className={styles.inputWrapper}>
                  <Input
                    placeholder="Введите пароль"
                    type="password"
                    name="password"
                    id="password"
                    value={values.password}
                    inputClassName={styles.input}
                    containerClassName={styles.inputs__item}
                    icon={<Password />}
                    handleChange={changeHandler(setFieldValue, setFieldTouched)}
                    errors={errors}
                    touched={touched}
                    submitCount={submitCount}
                  />
                </div>
                <div className={styles.inputWrapper}>
                  <Input
                    placeholder="Подтвердите пароль"
                    type="password"
                    name="passwordConfirm"
                    id="passwordConfirm"
                    value={values.passwordConfirm}
                    inputClassName={styles.input}
                    containerClassName={styles.inputs__item}
                    icon={<Password />}
                    handleChange={changeHandler(setFieldValue, setFieldTouched)}
                    errors={errors}
                    touched={touched}
                    submitCount={submitCount}
                  />
                </div>
                <div className={styles.checkboxWrapper}>
                  <input
                    id="agreement"
                    type="checkbox"
                    checked={values.agreement}
                    onChange={checkboxHandler(setFieldValue, setFieldTouched)}
                    className={styles.checkbox}
                  />
                  <label htmlFor="agreement">
                    <span>
                      Выражаю
                      <a href="/agreement" target="_blank">
                        согласие
                      </a>
                      на обработку моих персональных данных
                    </span>
                  </label>
                  {(touched.agreement || submitCount > 0) && !!errors.agreement && (
                    <div className={styles.errorMessage}>{errors.agreement}</div>
                  )}
                </div>
              </div>
              <div className={styles.inputWrapper}>
                <ReCAPTCHA
                  sitekey="6LcNicoZAAAAAKZ8L6U21ylVl3Xu1qaJmVfToaOG"
                  onChange={handleChangeCaptha}
                  size={window.innerWidth < 400 ? 'compact' : 'normal '}
                />
              </div>
              <RectangleButton
                title="Зарегистрироваться"
                externalStyle={styles.loginButtons}
                onClick={handleSubmit}
              />
            </div>
          )}
        </Formik>
        <div className={styles.registration}>
          Уже есть аккаунта? <Link to="/login">Войти</Link>
        </div>
      </div>
    </div>
  )
}

Rregistration.propTypes = {
  onRegister: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
}

Rregistration.defaultProps = {
  onRegister: noop,
  history: noop,
}

export default withRouter(connect(null, mapDispatchToProps)(Rregistration))
